.header {
  display: flex;
  flex-direction: row;
  flex-grow: 1;
  min-height: 48px;
  z-index: 100;
  box-shadow: rgb(0 0 0 / 8%) 0 1px 0;
  color: rgba(255, 255, 255, 1);
  background: #323957;
  // border: 1px solid rgba(255, 255, 255, 0.1);

  .header__branding {
    align-items: center;
    align-self: stretch;
    display: flex;
    flex-direction: row;
    flex-shrink: 0;
    outline: none;
    position: relative;
    border-radius: 2px;
    text-decoration: none;
    transition: color 80ms cubic-bezier(.165, .84, .44, 1),
      background 80ms linear;
    cursor: pointer;
    padding: 0 1rem;

    @media (max-width: 576px) {
      padding: 0 1px;
    }

    &:hover,
    &:focus {
      // background: #5C5F7A;
      text-decoration: none !important;
    }


    // @{    padding: 0 0.2rem 0 0.3rem;}

    .header__branding-logo {
      justify-content: center;
      flex-shrink: 0;
      align-self: center;
      display: inline-flex;
      width: 98px;
      font-size: 1.3125rem;

      @media (max-width: 576px) {
        // width: 90px;
      }
    }
  }

  .header__middle {
    display: flex;
    flex-direction: row;
    flex-grow: 1;
    min-width: 50px;

    .header__middle-container {
      flex-grow: 1;
      display: flex;
      align-items: center;
      padding-left: 3px;
      min-width: 50px;
    }
  }
}

.nav-home--button {
  @media (max-width: 576px) {
    display: none;
  }
}

.header__services-button,
.header__action-button,
.header__home-button {
  display: flex;
  flex-direction: row;
  align-items: center;
  cursor: pointer;
  align-self: stretch;
  display: flex;
  flex-shrink: 0;
  outline: none;
  position: relative;
  transition: color 80ms cubic-bezier(.165, .84, .44, 1), background 80ms linear;
  text-decoration: none;
  border-radius: 4px;
  padding: 0 8px;
  max-width: 200px;
  font-weight: 400;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-weight: 600;
  font-size: 14px;
  height: 32px;
  margin: 8px;
  color: #ADB0C3;

  @media (max-width: 576px) {
    margin-left: 2px;
    margin-right: 2px;
    font-size: 12px;
  }

  &:hover,
  &:focus,
  &[aria-expanded=true] {
    text-decoration: none;
    background: #5C5F7A;
  }


}

.header__home-button {
  .header__home-icon {
    margin-right: 8px;

    @media (max-width: 576px) {
      margin-right: 2px;
    }
  }

  .header__home-txt {
    color: #ADB0C3;
  }
}

.header__services-button {
  .header__services-icon {
    margin-right: 8px;
  }

  .header__services-txt {
    color: #ADB0C3;
  }
}

.header__services-container {
  .szh-menu {
    top: 1px !important;
    width: 450px;
    max-height: auto;
  }

  .szh-menu__header {
    padding: 0;
    color: inherit;
  }

  .header__services-header {
    margin: 0;
    padding: 5px 15px 4px;
    font-size: 16px;
    line-height: 2;
    font-weight: 600;
  }

  .szh-menu__group {
    margin: 0 10px;
    margin-top: 10px;
  }

  .szh-menu__item {
    margin-bottom: 16px;

    @media (hover: hover),
    screen and (-ms-high-contrast: active),
    (-ms-high-contrast: none) {
      .header__services-label:hover {
        color: #ec7211 !important;
      }
    }

  }

  .header__services-label {
    font-size: 16px;
    line-height: 22px;
    white-space: normal;
    overflow-wrap: break-word;
    text-align: left;
    box-sizing: content-box !important;
    -webkit-text-size-adjust: auto !important;
    text-size-adjust: auto !important;
    letter-spacing: normal !important;
    margin: 0;
    padding: 0;
    font-weight: normal;
    width: 100%;
  }

  .header__services-description {
    width: 100%;
    font-size: 12px;
    line-height: 16px;
    white-space: normal;
    overflow-wrap: break-word;
    text-align: left;
    box-sizing: content-box !important;
    -webkit-text-size-adjust: auto !important;
    text-size-adjust: auto !important;
    letter-spacing: normal !important;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0) !important;
    margin: 4px 0 0;
    padding: 0;
  }
}

.szh-menu,
.szh-menu__item,
.szh-menu__item--hover {
  padding: 0px !important;
  background-color: #FFFFFF !important;
}

.header__action-icon {
  flex-shrink: 0;
  align-self: center;
  display: inline-flex;
  font-size: 1rem;
  line-height: 1rem;
  color: #ADB0C3;
}

.header__action-menu {
  &.quickaction--menu {
    .szh-menu {
      top: 0 !important;
      padding: 10px !important;
      width: 320px;
      left: -280px !important;

      @media (max-width: 576px) {
        height: 100vh;
        width: 100vw;
        left: calc(50% - 100vw) !important;
      }
    }

    .szh-menu__item {
      padding: 0 4px !important;
      margin-top: 10px;
    }
  }

  &.notification--menu {
    .szh-menu {
      height: 400px;
      width: 500px;
      top: 0 !important;
      left: -455px !important;
    }

    .szh-menu__header {
      padding: 0;
      color: inherit;
    }

    .szh-menu__item {
      padding: 0 6px !important
    }
  }

  &.accounts--menu {
    .szh-menu {
      width: 320px;
      top: 0 !important;
      left: -280px !important;
    }
  }
}

.header__account-trigger {
  position: relative;
  height: auto;
  min-width: auto;
  min-height: auto;
  border-style: none;
  border-width: 0;
  padding: 0;
  margin: 0;
  outline-style: none !important;
  background-color: transparent;
  text-decoration: none;
  text-align: left;
  cursor: pointer;
}

.header__account-hed {
  display: flex;
  align-items: center;
  cursor: pointer;
  font-size: 13px;
  width: 100%;
  height: 48px;
  color: #333;
}

.header__account-picture {
  position: relative;
  width: 100%;
  height: 100%;
  border-radius: 4px;
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
  width: 32px;
  height: 32px;
  margin: 8px;
}

.header__account-profilepicture {
  position: relative;
  width: 100%;
  height: 100%;
  border-radius: 50%;
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
}