.receipt-popup--files {
    &:focus-visible {
        outline: none !important;
    }

    &>* {
        &:focus-visible {
            outline: none !important;
        }
    }

    .file-dropzone {
        padding: 0 !important;
        display: flex;
        flex-direction: column;
        flex-grow: 1;
    }

    .dropzone-input-wrapper {
        outline: none !important;
        font-size: 0.75rem;
        font-weight: 400;
        line-height: 1.5rem;
        letter-spacing: .00625em;
        color: rgba(0, 0, 0, 0.6);

        &.has-files {
            min-height: auto;
            align-items: start;
        }

        &:focus-visible {
            outline: none !important;
        }
    }

    .dropzone {
        margin: 0 !important;
        border: none !important;
        flex: 1 auto;
        justify-content: start;
        align-items: start;
    }

    .droppedfiles-grid {
        padding: 5px !important;
        margin: 0 !important;
    }
}

.receipt-popup--description {
    padding: 4px 8px;
    margin: 5px 0px;

    .description--label {
        box-sizing: border-box;
        font-variant-ligatures: none;
        outline: none;
        white-space: pre-wrap;
        word-wrap: break-word;
        position: absolute;
        width: 100%;
        pointer-events: none;
        min-height: 43px;
        padding: 10px 15px;
        font-size: 0.875rem;
        font-weight: 400;
        line-height: 1.5rem;
        letter-spacing: .00625em;
        color: rgba(0, 0, 0, 0.6);
    }

    .description--input-tooltip {
        font-size: 0.75rem;
        font-weight: 400;
        line-height: 1.5rem;
        letter-spacing: .00625em;
        border-radius: 4px;
    }

    .description--input {
        white-space: pre-wrap;
        word-wrap: break-word;
        min-height: 43px;
        padding: 10px 15px;
        transition: box-shadow 0.218s ease-in-out, background-color 0.218s ease-in;
        font-size: 0.875rem;
        font-weight: 400;
        line-height: 1.5rem;
        letter-spacing: .00625em;
        border-color: #fff !important;
        border: none !important;
        // border-top: 1px solid #CDCDE1 !important;
        outline: none !important;
        border-radius: 0 !important;
        padding-inline-start: 15px !important;
        padding-inline-end: 15px !important;
        height: auto !important;

        border-radius: 8px !important;
        background-color: #ffffff !important;
        border: 1px solid #CDCDE1 !important;
        color: #0b1b34 !important;
        padding: 5px 4px;
        line-height: 1.428571429 !important;
        width: 100% !important;
        font-size: 13px;

        &:hover,
        &:focus,
        &:active,
        &:visited,
        &:focus-visible,
        &[data-focus-visible] {
            outline: none !important;
            border: 1px solid;
            border-color: rgb(0, 103, 181) !important;
        }
    }
}

.receipt-popup--footer {
    align-items: center;
    color: rgba(0, 0, 0, 0.54);
    font-size: 12px;
    line-height: 26px;
    margin: 4px 0;
    padding: 4px 0 !important;
    transition: background-color 0.218s ease-in-out, box-shadow 0.218s ease-in-out;
    box-shadow: 0 -2px 5px rgb(0 0 0 / 20%);

    &.body--scroll {
        box-shadow: 0 -2px 5px rgb(0 0 0 / 20%);
    }
}

.receipt-popup--action {
    background-position: center;
    background-repeat: no-repeat;
    background-size: 18px 18px;
    border-radius: 50%;
    border: 1px solid transparent;
    height: 24px;
    opacity: 0.54;
    width: 24px;
    cursor: pointer;
    display: inline-block;
    outline: none !important;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;

    &.action--primary {
        height: 32px;
        margin: 0 8px;
        width: 32px;
        color: #202124;
        opacity: 0.71;

        &:hover {
            opacity: 0.87;
            background-color: rgba(95, 99, 104, 0.157);
        }

        &:focus {
            border-color: rgba(0, 0, 0, .6);
        }
    }
}

.receipt-popup--button {
    transition: none;
    border-width: 1px;
    font-size: 13px;
    line-height: 22px;
    font-weight: 700;
    text-align: center;
    vertical-align: middle;
    touch-action: manipulation;
    border: 1px solid transparent;
    white-space: nowrap;
    padding: 4px 10px;
    outline: none;
    border-radius: 8px;

    &:not(:last-child) {
        margin-right: 5px;
    }

    &.button-close {
        color: #fff;
        background: #fc3826;
        text-decoration: none;


        &:hover,
        &:active {
            background: #ec1703;
            text-decoration: none;
        }

        &:focus,
        &:focus-visible {
            background: #ec1703;
            border-color: transparent;
            box-shadow: 0 0 0 2px rgb(252 56 38 / 50%);
            color: #fff;
        }
    }

    &.button-save {
        color: #fff;
        background: #03d47c;
        text-decoration: none;

        &:hover,
        &:active {
            background: #03d47c;
            text-decoration: none;
        }

        &:focus,
        &:focus-visible {
            background: #03d47c;
            border-color: transparent;
            box-shadow: 0 0 0 2px rgb(4 190 113 / 50%);
            color: #fff;
        }
    }
}