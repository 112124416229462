.wp-sidebar--layout {
  height: calc((100vh - 41px) - 28px);

  @media only screen and (max-width: 505px) {
    height: calc((100vh - 41px) - 62px);
  }

  @media only screen and (max-width: 768px) {
    flex-direction: column;
  }
}

#wp-sidebar--layout__container {
  padding: 0 4rem;

  // Small devices (landscape phones, 576px and up)
  @media (max-width: 576px) {
    padding: 0 15px;
  }

  // Medium devices (tablets, 768px and up)
  @media (min-width: 768px) {}

  // Large devices (desktops, 992px and up)
  @media (min-width: 992px) {}

  // Extra large devices (large desktops, 1200px and up)
  @media (min-width: 1200px) {}
}

.wp-sidebar--layout__content {
  &>div[wp-sidebar-layout-main-content-region=content] {
    padding-bottom: 2rem;
    flex: 1;
    display: flex;
    flex-direction: column;
  }
}



.sidebar-layout__navigation {
  width: 280px;
  margin-left: -240px;
  left: 0;
  overflow: auto;
  flex-shrink: 0;
  flex-grow: 0;
  top: 0;
  bottom: 0;
  position: relative;
  z-index: 1;
  background-color: #fff;
  box-shadow: 0 1px 1px 0 rgba(0, 28, 36, .3), 1px 1px 1px 0 rgba(0, 28, 36, .15), -1px 1px 1px 0 rgba(0, 28, 36, .15);
  outline: none;
  word-wrap: break-word;

  &.sidebar-layout__navigation--open {
    width: 280px;
    margin-left: 0px;

    &+.sidebar-layout__toggle {
      left: -4rem;
    }
  }

  @media only screen and (max-width: 768px) {
    display: none;
  }
}

.sidebar-layout__navigation--close-area {
  text-decoration: none;
  font-size: 1.4rem;
  line-height: 2rem;
  position: absolute;
  outline: none;
  right: 1.5rem;
  top: 1.5rem;
}

.sidebar-layout__navigation--close-btn {
  font-size: 1.4rem;
  line-height: 2rem;
  border-radius: 2px;
  border: 1px solid;
  padding: 0.4rem 2rem;
  font-weight: 700;
  letter-spacing: .25px;
  display: inline-block;
  cursor: pointer;
  text-align: left;
  background: transparent;
  color: #545b64;
  border-color: transparent;
  position: relative;
  text-decoration: none;
  padding-left: 0.6rem;
  padding-right: 0.6rem;
}

.sidebar-layout__navigation--close-icon {
  position: relative;
  margin-right: 0.4rem;
  left: 0;

  .sidebar-layout__navigation--close-span {
    display: inline-block;
    vertical-align: top;
    width: 1.6rem;
    height: 2rem;
    padding: 0.2rem 0;
    box-sizing: border-box;
    stroke: currentColor;

    & svg {
      fill: none;
      stroke-width: 2px;
      stroke: currentColor;

      & * {
        stroke-width: 2px;
        stroke: currentColor;
      }
    }
  }
}


.sidebar-layout__toggle {
  position: absolute;
  z-index: 1;
  display: flex;
  flex-shrink: 0;
  flex-grow: 0;
  justify-content: center;
  width: 4rem;
  top: 0;
  bottom: 0;
  left: 0;
  background-color: #fff;
  padding-top: 0.5rem;
  box-sizing: border-box;
  cursor: pointer;

  @media only screen and (max-width: 768px) {
    display: none;
  }

  &:hover {
    background-color: #eaeded;
  }

  .sidebar-layout__toggle-icon {
    color: #545b64;
  }

  .sidebar-layout__toggle-btn {
    font-size: 1.4rem;
    line-height: 2rem;
    border-radius: 2px;
    border: 1px solid;
    padding: 0.4rem 2rem;
    font-weight: 700;
    letter-spacing: .25px;
    display: inline-block;
    cursor: pointer;
    text-align: left;
    background: transparent;
    color: #545b64;
    border-color: transparent;
    position: relative;
    text-decoration: none;
    padding-left: 0.6rem;
    padding-right: 0.6rem;
  }

  .sidebar-layout__toggle-icon-cont {
    display: inline-block;
    vertical-align: top;
    width: 1.6rem;
    height: 2rem;
    padding: 0.2rem 0;
    box-sizing: border-box;
    stroke: currentColor;

    svg {
      pointer-events: none;
      fill: none;
      width: 1.6rem;
      height: 1.6rem;
      stroke: currentColor;
      stroke-width: 2px;

      & * {
        stroke-width: 2px;
        stroke: currentColor;
      }
    }
  }
}

.sidebar-layout__navigation--header {
  font-size: 1.8rem;
  line-height: 2rem;
  padding: 2rem 5.6rem 2rem 3rem;

  .sidebar-layout__navigation--header-link {
    text-decoration: none;
    color: #16191f;
    font-weight: 700;
    background-color: transparent;

    &:hover {
      text-decoration: none;
      color: #e3122a !important;
    }
  }
}

.sidebar-layout__navigation--divider {
  border: none;
  border-top: 1px solid #eaeded;
  margin-bottom: 1.5rem;
  margin-top: -1px;
}

.sidebar-layout__navigation--area {
  display: flex;
  flex-direction: column;
  overflow: hidden;
}


.sidebar-layout__toggle--mobile {
  display: none;

  &.sidebar-layout__toggle--unfocusable * {
    visibility: hidden;
  }

  @media only screen and (max-width: 768px) {
    display: flex;
    align-items: center;
    flex-shrink: 0;
    justify-content: space-between;
    height: 4rem;
    z-index: 4;
    box-shadow: 0 1px 1px 0 rgba(0, 28, 36, .3), 1px 1px 1px 0 rgba(0, 28, 36, .15), -1px 1px 1px 0 rgba(0, 28, 36, .15);
  }



  .sidebar-layout__navigation--landmark {
    &:last-child {
      .sidebar-layout__toggle-icon {
        border-right: 1px solid #d5dbdb;
      }
    }

    &>.sidebar-layout__toggle-icon {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 4rem;
      width: 4rem;
      box-sizing: border-box;
      color: #545b64;
      cursor: pointer;

      &:hover {
        background-color: #eaeded;
      }
    }
  }

  .sidebar-layout__toggle-btn {
    font-size: 1.4rem;
    line-height: 2rem;
    border-radius: 2px;
    border: 1px solid;
    padding: 0.4rem 2rem;
    font-weight: 700;
    letter-spacing: .25px;
    display: inline-block;
    cursor: pointer;
    text-align: left;
    background: transparent;
    color: #545b64;
    border-color: transparent;
    position: relative;
    text-decoration: none;
    padding-left: 0.6rem;
    padding-right: 0.6rem;

    &:hover {
      background: transparent;
      color: #16191f;
      border-color: transparent;
      text-decoration: none;
    }

    .sidebar-layout__toggle-icon-cont {
      display: inline-block;
      vertical-align: top;
      width: 1.6rem;
      height: 2rem;
      padding: 0.2rem 0;
      box-sizing: border-box;
      color: #545b64;
      stroke: currentColor;

      svg,
      svg * {
        stroke-width: 2px;
      }

      svg {
        width: 1.6rem;
        height: 1.6rem;
        pointer-events: none;
        fill: none;
      }
    }
  }
}

.sidebar-layout__mobile--navigation {
  overflow: hidden;
  flex-shrink: 0;
  flex-grow: 0;
  top: 0;
  bottom: 0;
  position: relative;
  z-index: 1;
  background-color: #fff;
  outline: none;
  word-wrap: break-word;
  right: 0;
  bottom: 0;
  margin: 0;
  position: fixed;
  box-shadow: none;
  z-index: 5;
  top: 41px;
  left: -100%;
  width: 100%;

  &.sidebar-layout__mobile--navigation-open {
    left: 0px;
  }
}
