.summary-table {
  margin-top: 16px;

  thead {
    th {
      border-bottom: 1px solid;
      border-color: #ECECF9;

      &.total {
        text-align: right;
      }

      &.to-be-reimbursed {
        text-align: right;
      }

      &.status {
        text-align: center;
        border: 0px !important;
        border-bottom: 1px solid !important;
        border-color: #ECECF9 !important;
        border-radius: 0px;
      }
    }
  }

  .text-left {
    text-align: right;
  }

}

.drag-receipt_modal--content {
  box-shadow: 0 1.2px 0.2px 0 rgb(0 0 0 / 13%),
    0 0.6px 1.8px 0 rgb(0 0 0 / 11%) !important;
  border-radius: 4px !important;
  border: 1px solid #ECECF9 !important;
}

.drag-receipt_modal--header {
  box-shadow: 0 1.2px 0.2px 0 rgb(0 0 0 / 13%),
    0 0.6px 1.8px 0 rgb(0 0 0 / 11%) !important;
  border-bottom: 1px solid #ECECF9 !important;
}

.status {
  border: 1px solid #FFFFFF;
  border-radius: 2px;

  &.status--draft {
    color: #0b1b34;
    background: #ececec;
    border-color: #ececec;
  }

  &.status--awaiting-approval {
    color: #FFFFFF;
    background: #06509a;
    border-color: #06509a;
  }

  &.status--approved {
    background: #03d47c;
    border-color: #03d47c;
    color: #fff;
  }

  &.status--rejected {
    background: #fc3826;
    border-color: #fc3826;
    color: #fff;
  }

  &.status--paid {
    color: #FFFFFF;
    background: #0b4112;
    border-color: #0b4112;
  }


}

.css-73tqa4 {
  z-index: 100000;
}

.drag-receipt_modal--form {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}