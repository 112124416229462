.welcome-layout__cta {
  position: relative;
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  max-width: none;
  padding: 30px;
  text-align: center;

  @media only screen and (min-width: 769px) {
    max-width: 768px;
  }

  @media only screen and (min-width: 980px) {
    max-width: 980px;
  }

  &:after,
  &:before {
    content: " ";
    display: table;
  }
}

.welcome-layout__cta-textarea {
  box-sizing: border-box;
  padding: 0 10px;
  width: 100%;
  float: left;
  position: relative;
  float: left;
}

.welcome-layout__cta-head {
  word-wrap: break-word;
  font-weight: 900;
  color: #fff;
  text-align: center;
  margin-top: 10px;
  margin-bottom: 0rem;
  font-size: 26px;
  line-height: 1.3;
  font-family: inherit;

  @media only screen and (min-width: 481px) {
    font-size: 28px;
    line-height: 1.2;
  }

  @media only screen and (min-width: 980px) {
    font-size: 30px;
    line-height: 1.2;
  }
}

.welcome-layout__cta-lead {
  font-size: 14px;
  font-weight: 600;
  line-height: 1.6;
  display: block;
  word-wrap: break-word;
  color: #fff;
  text-align: center;
  font-family: inherit;
}

.welcome-layout__cta-btn--cont {
  position: relative;
  width: 100%;
  padding: 0;
  width: auto;
  margin-left: 0 -10px;

  @media only screen and (min-width: 769px) {
    max-width: 768px;
  }

  @media only screen and (min-width: 980px) {
    max-width: 980px;
  }
}

.welcome-layout__cta-btn--area {
  margin: 15px 0;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.welcome-layout__cta-btn {
  margin-bottom: 0;
  font-weight: 700;
  text-align: center;
  vertical-align: middle;
  cursor: pointer;
  background-image: none;
  border: 1px solid transparent;
  white-space: nowrap;
  padding: 4px 10px;
  font-size: 14px;
  line-height: 1.428571429;
  border-radius: 8px;
  line-height: 22px;
  width: 150px;

  background: #1577da;
  color: #fff;

  &:active,
  &:hover {
    background: #06509a;
    text-decoration: none;
  }

  &:focus {
    background: #06509a;
    border-color: transparent;
    box-shadow: 0 0 0 2px rgb(7 77 146 / 50%);
    color: #fff;
  }
}