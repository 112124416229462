* {
  box-sizing: inherit;
}

html {
  // font-size: 62.5%;
}

html,
body {
  height: 100%;
  width: 100%;
}

body {
  display: flex;
  font-family: 'Montserrat', sans-serif;
  font-size: .875rem;
  padding: 0;
  margin: 0;
  background-color: rgba(255, 255, 255, 1);
  color: rgba(0, 0, 0, .9);
}

#root {
  display: flex;
  flex-grow: 1;
  overflow: hidden;
}

.wc-ppl__page {
  display: flex;
  flex-grow: 1;
  overflow: hidden;
  flex-direction: column;
}

.wc-ppl__page-layout {
  display: flex;
  flex-grow: 1;
  overflow: hidden;
  flex-direction: column;
}

.wc-ppl__page-header {
  display: flex;
  flex-direction: row;
  position: relative;
  flex-shrink: 0;
}

.wc-ppl__page-content {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  overflow: hidden;

  @media (min-width: 900px) {
    flex-direction: row;
  }
}

.wc-ppl__page-footer {
  display: flex;
  flex-direction: row;
  flex-shrink: 0;
}

button,
input,
optgroup,
select,
textarea {
  font-family: inherit;
  font-size: 100%;
  line-height: 1.15;
  margin: 0;
}

button,
input {
  overflow: visible;
}

button,
select {
  text-transform: none;
}

[type='button'],
[type='reset'],
[type='submit'],
button {
  -webkit-appearance: button;
}

a {
  background-color: transparent;
}

#wp-page--content,
.css-15x5q8w {
  width: 100%;

  &>*:not(style)~*:not(style) {
    margin-top: 0px !important;
    margin-inline-end: 0px !important;
    margin-bottom: 0px !important;
    margin-inline-start: 0px !important;
  }
}

hr {
  box-sizing: content-box;
  height: 0;
  overflow: visible;
}


.color {
  &.default {
    color: inherit !important;
  }

  &.success {
    color: rgba(127, 184, 0, 1) !important;

  }

  &.warning {
    color: rgba(177, 133, 37, 1) !important;
  }

  &.error {
    color: rgba(232, 17, 35, 1) !important;
  }

  &.info {
    color: rgba(0, 91, 161, 1) !important;
  }
}