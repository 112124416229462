.table-cards {
    border-collapse: separate;
    border-spacing: 0 10px;
    margin-bottom: -10px;
    table-layout: fixed;
    font-size: 12px;
    background: 0 0;
    border: none;
    border-collapse: separate;
    border-spacing: 0 10px;
    margin-bottom: -10px;
    clear: both;
    margin: 0 auto;
    position: relative;
    top: -1px;
    width: 100%;
    word-wrap: break-word;
    font-family: "Montserrat", sans-serif;


    caption,
    td,
    th {
        text-align: left;
        font-weight: 400;
        vertical-align: middle;
    }

    thead,
    tr {
        margin: 0;
        padding: 0;
        border: 0;
        font: inherit;
        font-size: 100%;
        vertical-align: baseline;
    }

    thead {
        // position: -webkit-sticky;
        // position: sticky;
        // top: 0px;
        z-index: 1;
        background: #fff;

        th {
            background: #fff;
            border-bottom: none;
            color: #0b1b34;
            font-size: 11px;
            font-weight: 600;
            padding: 10px 15px;
            border-bottom: 1px solid #ececec;
            text-transform: uppercase;
            white-space: nowrap;
            font-family: "Montserrat", sans-serif;

            &.reportName {
                padding: 3px 7px 3px 0;
            }

            &.approver,
            &.approvee {
                padding-right: 18px;
                padding-left: 7px;
            }

            &.subTotal {
                padding: 7px 17px 7px 4px;
                text-align: right;
                width: 200px;

                .th-inner {
                    text-align: right;
                }

                .th-inner--text {
                    text-align: right;
                    justify-content: flex-end;
                }
            }

            &.submitted {
                width: 150px;
                padding-right: 18px;
                padding-left: 7px;

                .th-inner--text {
                    justify-content: flex-end;
                }
            }
        }
    }

    tbody {
        tr {
            border-radius: 8px;
            // box-shadow: 0 8px 16px rgba(0, 0, 0, .03), 0 3px 6px rgba(0, 0, 0, .04);
            border-bottom: none;
            // padding-bottom: 20px;
            // padding-top: 20px;
            text-align: left;
            font-weight: 400;
            vertical-align: middle;
            background-color: #fff;

            td {
                // padding: 15px 7px;
                padding: 7px 7px;
                height: 30px;
                font-weight: 400;
                color: #0b1b34;
            }

            td:first-child {
                border-radius: 8px 0 0 8px;
            }

            td:last-child {
                border-radius: 0 8px 8px 0;
            }
        }
    }

    th:first-child {
        border-radius: 8px 0 0 0;
    }

    label {
        display: inline-block;
        max-width: 100%;
        margin-bottom: 5px;
        font-weight: 700;
    }

    label {
        font-weight: 400;
    }

    .filter-state {
        border-radius: 12px;
        display: inline-block;
        margin: 0;
        padding: 0;
        text-align: center;
    }

    .filter-state {
        &.smallState {
            border-radius: 9px;
            cursor: pointer;
            display: inline-block;
            font-size: 10px;
            font-weight: 500;
            height: 17px;
            line-height: 17px;
            margin: 0 0 0 64px;
            position: relative;
            top: -2px;
            width: 66px;
            text-transform: capitalize;
        }

        &.with-amount {
            margin-bottom: 6px;
        }

        &.draft {
            color: #0b1b34;
            background: #ececec;
            border-color: #ececec;
        }

        &.open {
            background: #0185ff;
            color: #fff;
        }

        &.pending-approval {
            color: #FFFFFF;
            background: #0185ff;
            border-color: #0185ff;
        }

        &.approved {
            background: #03d47c;
            border-color: #03d47c;
            color: #fff;
        }

        &.rejected {
            background: #fc3826;
            border-color: #fc3826;
            color: #fff;
        }

        &.paid {
            color: #FFFFFF;
            background: #0b4112;
            border-color: #0b4112;
        }
    }

    .total-amount {
        font-size: 16px;
        font-weight: 700;
        line-height: 1.4;
        // padding-bottom: 17px;
        // padding-top: 17px;

        span {
            text-transform: capitalize;
            font-size: 11px;
            position: relative;
            top: 2px;
            vertical-align: top;

        }
    }
}