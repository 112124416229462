.pdg-dashboard--content {
    outline: 0;
    flex-grow: 1;
    overflow-y: auto;
    position: relative;

    .content-area {
        width: 100%;
        position: absolute;
        left: 0;
        top: 0;
        height: 100%;
    }

    .content-area---inner {
        position: relative;
        background: rgba(255, 255, 255, 1);
        color: initial;
        background-color: inherit;
        cursor: default;
        height: 100%;
        padding: 10px 0px;

        &.pdg-home-content {
            padding: 0px
        }

        @media (min-width: 900px) {
            padding: 10px 0px;

            &.pdg-home-content {
                padding: 0px
            }
        }


    }

    .reports-container {
        padding: 10px 15px;
        padding: 0px;
        padding-bottom: 0;
        height: 100%;
        margin-top: 0px;

        @media (min-width: 900px) {
            padding: 10px 20px;
            padding: 0px;
        }
    }

    .pdg_dash-content_table-area {
        position: relative;
        background: rgba(255, 255, 255, 1);
        color: initial;
        background-color: inherit;
        cursor: default;
        font-size: 13px;
        color: #0b1b34;
        line-height: 22px;
        height: 100%;

        table {
            border-collapse: collapse;

            thead {
                position: -webkit-sticky;
                position: sticky;
                top: 0;
                z-index: 11;
                background: #F3F2FB;
                border-bottom: 1px solid;
                border-color: #ECECF9;
                box-shadow: inset 0 0 0 9999px transparent;

                tr {
                    border-bottom: 1px solid;
                    border-color: #ECECF9;
                    background: #F3F2FB;

                    th {
                        box-sizing: content-box;
                        border-bottom: 1px solid;
                        border-color: #ECECF9;
                        font-size: 11px;
                        line-height: 1.2;
                        color: #0b1b34;
                        word-wrap: none;
                        white-space: nowrap;
                        padding: 0.5rem 0.5rem;
                        font-weight: 700;
                        // color: #9696B7;
                        font-family: inherit;
                        background: #F3F2FB;
                        cursor: pointer;

                        &:active,
                        &:hover {
                            // background: rgba(234, 234, 234, 1);
                        }

                        &.view {
                            min-width: 50px;
                        }

                        &.employee {
                            min-width: 150px;
                        }

                        &.supervisor {
                            min-width: 150px;
                        }

                        &.position {
                            min-width: 200px;
                        }


                        &.review-period {
                            min-width: 170px;
                        }

                        &.overall-feedback-from-employee {
                            min-width: 350px;
                        }

                        &.overall-feedback-from-supervisor {
                            min-width: 250px;
                        }

                        &.supervisor {
                            min-width: 150px;
                        }

                        &.overall-rating {
                            min-width: 150px;
                        }

                        &.status {
                            min-width: 80px;
                            text-align: center;
                            border: 0px !important;
                            border-bottom: 1px solid !important;
                            border-color: #ECECF9 !important;
                            border-radius: 0px;
                        }
                    }
                }
            }

            tbody {
                tr {
                    &:hover {
                        // background: rgb(234, 234, 234);
                    }

                    td {
                        box-sizing: content-box;
                        border-bottom: 1px solid;
                        border-color: #ECECF9;
                        font-size: 13px;
                        line-height: 1.4;
                        color: #0b1b34;
                        padding: 0.5rem 0.5rem;
                        font-weight: 400;
                        font-family: inherit;
                        text-align: initial;
                        vertical-align: top
                    }
                }
            }
        }

    }
}

.pdg-dashboard--head {
    padding: 5px 15px;
    flex-shrink: 0;
    border-bottom: 1px solid #ececec;

    @media (min-width: 900px) {
        // padding: 15px 20px;
        padding: 5px 15px;
    }

    .heading {
        font-size: 20px;
        font-weight: 700;
        color: #0b1b34;
        line-height: 1.4;
        margin-bottom: 0;
    }

    .action--button {
        transition: none;
        border-width: 1px;
        font-size: 13px;
        line-height: 22px;
        font-weight: 700;
        text-align: center;
        vertical-align: middle;
        touch-action: manipulation;
        border: 1px solid transparent;
        white-space: nowrap;
        padding: 4px 10px;
        border-radius: 8px;
        outline: none;

        &.new-pdg,
        &.print-pdg {
            background: #3d54af;
            border-color: transparent;
            color: #fff;

            &:hover,
            &:active {
                background: #20368b;
                text-decoration: none;
                border-color: transparent;
            }

            &:focus,
            &:focus-visible {
                background: #20368b;
                text-decoration: none;
                border-color: transparent;
                box-shadow: 0 0 0 2px rgb(115 103 240 / 65%);
            }
        }

        &.edit-pdg,
        &.submit-pdg {
            background: #009CCF;
            border-color: transparent;
            color: #fff;

            &:hover,
            &:active {
                background: #31d2f2;
                text-decoration: none;
                border-color: transparent;
            }

            &:focus,
            &:focus-visible {
                background: #31d2f2;
                text-decoration: none;
                border-color: transparent;
                box-shadow: 0 0 0 2px rgb(44 188 216 / 50%);
            }
        }


    }
}

.pdg-detail-head {
    flex-wrap: wrap;

    @media (min-width: 900px) {}

    .pdg-detail-head--col {
        padding: 0.5rem 1.5rem;
        flex: 0 0 auto;
        width: 33.33333333%;
        color: #0b1b34;

        @media (min-width: 900px) {}

        .pdg-detail-head--label {
            font-size: 12px;
            line-height: 18px;
            font-weight: 400;
            text-transform: capitalize;
            color: #0b1b34;
        }

        .pdg-detail-head--value {
            font-size: 13px;
            line-height: 22px;
            font-weight: 700;
            text-transform: capitalize;
            color: #0b1b34;
        }
    }
}

.pdg-detail-content {
    padding: 0px 15px;
    margin-top: 16px;

    &.new-pdg-content {
        padding: 10px 15px 20px;
        border-top: 1px solid #ececec;

        @media (min-width: 900px) {
            padding: 10px 15px 20px;
        }
    }

    @media (min-width: 900px) {
        padding: 0 20px
    }
}

.pdg-detail-submit {
    &.new-pdg-content {
        padding: 10px 15px 20px;

        @media (min-width: 900px) {
            padding: 10px 15px 20px;
        }
    }

    .action--button {
        transition: none;
        border-width: 1px;
        font-size: 13px;
        line-height: 22px;
        font-weight: 700;
        text-align: center;
        vertical-align: middle;
        touch-action: manipulation;
        border: 1px solid transparent;
        white-space: nowrap;
        padding: 4px 10px;
        border-radius: 8px;
        outline: none;

        &.submit-pdg {
            background: #009CCF;
            border-color: transparent;
            color: #fff;

            c &:hover,
            &:active {
                background: #31d2f2;
                text-decoration: none;
                border-color: transparent;
            }

            &:focus,
            &:focus-visible {
                background: #31d2f2;
                text-decoration: none;
                border-color: transparent;
                box-shadow: 0 0 0 2px rgb(44 188 216 / 50%);
            }
        }
    }
}

.pdg-detail-tabList {
    margin-top: 10px;
    border: 0;
    border-bottom: 0;

    .pdg-detail-tab {
        transition: none;
        font-size: 13px;
        line-height: 22px;
        font-weight: 500;
        text-align: center;
        vertical-align: middle;
        touch-action: manipulation;
        border: 1px solid transparent;
        white-space: nowrap;
        padding: 4px 10px;
        border-radius: 8px;
        outline: none;
        color: #0b1b34;
        margin: 2px 0px;
        text-transform: uppercase;


        &[aria-selected=true] {
            background: #3d54af;
            color: #fff;

            &:hover,
            &:active {
                background: #20368b;
                text-decoration: none;
                border-color: transparent;
            }

            &:focus-visible {
                background: #20368b;
                text-decoration: none;
                border-color: transparent;
                box-shadow: 0 0 0 2px rgb(115 103 240 / 65%);
            }
        }

        &:not([aria-selected=true]) {
            &:hover {
                color: #e3122a;
            }
        }
    }
}

.pdg-detail-tabs {
    width: 100%;

    .pdg-detail-tabPanel {
        padding: 0;
        outline-offset: 0;
        outline: 0;
    }
}

.pdg-detail-card {
    flex-direction: column;
    border: 1px solid #ECECF9;
    box-shadow: 0 1.2px 0.2px 0 rgb(0 0 0 / 13%), 0 0.6px 1.8px 0 rgb(0 0 0 / 11%);
    border-radius: 8px;
    overflow: hidden;
    padding: 16px;
    background-color: rgb(255, 255, 255);
    flex-grow: 1;
    margin-top: 16px;



    .pdg-detail-objectives--head {
        padding: 0.5rem 1rem;
        margin-bottom: 0;
        background: #F3F2FB;
        border-bottom: 1px solid rgb(234, 234, 234);
        margin-bottom: 1rem !important;

        h6 {
            font-size: 13px;
            line-height: 22px;
            font-weight: 700;
            color: #0b1b34;
        }
    }

    .pdg-detail-year-objectives--head {
        border-bottom: 1px solid rgb(234, 234, 234);
        padding-bottom: 1rem !important;
        margin-bottom: 1rem !important;

        h6 {
            font-size: 16px;
            line-height: 24px;
            font-weight: 600;
            color: #0b1b34;
            margin-bottom: 1rem;
        }

        p {
            font-size: 13px;
            line-height: 22px;
            font-weight: 400;
            color: #0b1b34;

        }
    }

    .pdg-detail-wiocc-values--head {
        border-bottom: 1px solid rgb(234, 234, 234);
        padding-bottom: 1rem !important;
        margin-bottom: 1rem !important;



        p {
            font-size: 13px;
            line-height: 22px;
            font-weight: 400;
            color: #0b1b34;

        }
    }

    .pdg-detail-development-needs--head {
        padding-bottom: 1rem !important;
        margin-bottom: 1rem !important;


        h6 {
            font-size: 16px;
            line-height: 24px;
            font-weight: 600;
            color: #0b1b34;
            margin-bottom: 1rem;

            &.contains {
                margin-top: 0.5rem;
                font-size: 14px;
                line-height: 22px;
                font-weight: 600;
            }
        }

        p {
            font-size: 13px;
            line-height: 22px;
            font-weight: 400;
            color: #0b1b34;

        }
    }

    .pdg-detail-self-evaluation--head {
        border-bottom: 1px solid rgb(234, 234, 234);
        padding-bottom: 1rem !important;
        margin-bottom: 1rem !important;

        h6 {
            font-size: 16px;
            line-height: 24px;
            font-weight: 600;
            color: #0b1b34;
            margin-bottom: 1rem;
        }
    }

    .pdg-detail-supervisor-evaluation--head {
        border-bottom: 1px solid rgb(234, 234, 234);
        padding-bottom: 1rem !important;
        margin-bottom: 1rem !important;

        h6 {
            font-size: 16px;
            line-height: 24px;
            font-weight: 600;
            color: #0b1b34;
            margin-bottom: 1rem;
        }

        p {
            font-size: 13px;
            line-height: 22px;
            font-weight: 400;
            color: #0b1b34;

        }
    }

    table {
        thead {
            tr {
                th {
                    box-sizing: content-box;
                    border-bottom: 1px solid;
                    border-color: #ECECF9;
                    font-size: 11px;
                    line-height: 1.2;
                    color: #0b1b34;
                    word-wrap: none;
                    white-space: nowrap;
                    padding: 0.5rem 0.5rem;
                    font-weight: 700;
                    font-family: inherit;
                    background: #F3F2FB;
                    letter-spacing: .5px;
                    font-family: inherit;
                }
            }
        }
    }
}


.pdg-create--form {
    .pdg-create--label {
        color: #0b1b34;
        line-height: 1.428571429 !important;
        font-size: 13px;
    }

    .pdg-create--input {
        border-radius: 8px;
        background-color: #ffffff;
        border: 1px solid #ececec;
        color: #0b1b34;
        padding: 5px 4px;
        line-height: 1.428571429 !important;
        width: 100% !important;
        font-size: 13px;

        &:hover,
        &:active,
        &:focus,
        &:focus-visible {
            border: 1px solid;
            border-color: rgb(0, 103, 181) !important;
            outline: none;
            box-shadow: none;
        }
    }

    .in-form-btn {
        transition: none;
        border-width: 1px;
        font-size: 13px;
        line-height: 22px;
        font-weight: 700;
        text-align: center;
        vertical-align: middle;
        touch-action: manipulation;
        border: 1px solid transparent;
        white-space: nowrap;
        padding: 4px 10px;
        border-radius: 8px;
        outline: none;

        &.add {
            border: 1px solid #009CCF;
            background: #fff;
            color: #009CCF;

            &:hover,
            &:active {
                background: #31d2f2;
                text-decoration: none;
                color: #fff;
                border-color: transparent;
            }

            &:focus,
            &:focus-visible {
                background: #31d2f2;
                text-decoration: none;
                border-color: transparent;
                color: #fff;
                box-shadow: 0 0 0 2px rgb(44 188 216 / 50%);
            }
        }

        &.delete {
            border: 1px solid #fc3826;
            background: #fff;
            color: #fc3826;

            &:active,
            &:hover {
                background: #ec1703;
                border-color: transparent;
                color: #fff;
            }

            &:focus {
                background: #ec1703;
                border-color: transparent;
                box-shadow: 0 0 0 2px rgb(252 56 38 / 50%);
                color: #fff;
            }
        }
    }
}

.pdg-detail-supervisor-evaluation--outline {
    font-size: 13px;
    line-height: 22px;
    font-weight: 400;
    color: #0b1b34;

    span {
        font-weight: 700;
    }

    .inner-outline {
        li {
            list-style-type: circle;
        }
    }
}