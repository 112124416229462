.dashboard__hed {
    border-collapse: separate;
    border-spacing: 0;
    caption-side: top;
    direction: ltr;
    empty-cells: show;
    font-family: serif;
    font-size: medium;
    font-style: normal;
    font-variant: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    list-style: disc outside none;
    tab-size: 8;
    text-align: left;
    text-indent: 0;
    text-shadow: none;
    text-transform: none;
    visibility: visible;
    white-space: normal;
    word-spacing: normal;
    box-sizing: border-box;
    font-size: 14px;
    line-height: 22px;
    color: #16191f;
    font-weight: 400;
    cursor: inherit;
    display: flex;
    width: 100%;
    flex-wrap: wrap;
    justify-content: space-between;

    .dashboard__hed-left {
        word-wrap: break-word;
        max-width: 100%;
        overflow: hidden;
        padding: 4px 0;
        margin-right: 8px;

        .dashboard__hed-title {
            min-width: 0;
            word-break: break-word;
            color: #16191f;

            h1 {
                &.dashboard__hed-text {
                    // font-size: 28px;
                    // line-height: 36px;
                    font-size: 22px;
                    line-height: 0px;
                    letter-spacing: normal;
                    font-weight: 600;
                    margin: 0;
                    display: inline;
                }
            }
        }
    }

    .dashboard__hed-right {
        display: flex;
        align-items: flex-start;
        padding: 8px 0;

        .dashboard__hed-actions {
            display: flex;
            flex-direction: row;
            flex-wrap: wrap;
            margin-left: calc(-1 * 8px);
            margin-bottom: calc(-1 * 8px);

            .dashboard__hed-action--wrapper {
                margin-left: 8px;
                margin-bottom: 8px;
                min-width: 1px;
            }
        }
    }

}