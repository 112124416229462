#footer-wrapper {
  position: fixed;
  bottom: 0;
  z-index: 1002;
  margin: 0;
  padding: 0;
  border: 0;
  width: 100%;

  .footer-inner {
    border-top: #696d9a;
    color: #fff;
    font-size: 12px;
    background-color: #1e2032;
    line-height: 14px;

    .footer-content {
      display: flex;
      margin: 0;
      padding: 7px 20px;
      flex-wrap: wrap;
      justify-content: space-between;
      box-sizing: border-box;
      align-items: center;

      @media only screen and (max-width: 768px) {
        justify-content: center;
      }

      .footer-content__left {
        margin-right: 25px;
        display: inline-flex;
        align-items: center;

        @media only screen and (max-width: 767px) {
          margin-right: 0px;
          padding: 5px 0px;
        }
      }

      #feedback {
        border: none;
        height: auto !important;
        background: none;
        padding: 0;
        margin: 0 25px 0 0 !important;
        font-size: 12px;
        cursor: pointer;
        color: #fff !important;
        text-decoration: none !important;

        &:active {
          outline: none;
        }

        &:focus,
        &:hover {
          background: none;
          outline: none;
          color: #e3122a !important;
        }
      }

      .footer-content__middle {
        order: 2;
        width: 100%;
        margin-top: 10px;
        text-align: center;
        color: #fff;
        font-size: 12px;

        @media only screen and (min-width: 1120px) {
          margin-top: 0px;
          flex-grow: 1;
          flex-basis: 0;
          padding: 0 5px;
          font-size: 12px;
          text-align: right;
          display: -webkit-box;
          display: flex;
          align-items: center;
          justify-content: flex-end;
          margin-right: 25px;
        }
      }

      .footer-content__right {
        display: inline-flex;
        align-items: center;

        @media only screen and (max-width: 767px) {
          padding: 5px 0px;
        }

        @media only screen and (min-width: 1120px) {
          order: 3;
          margin-bottom: 0;
        }

        .footer-content__nav {
          display: flex;
          align-items: center;
          flex-wrap: wrap;
          list-style: none;
          font-size: 12px;
          margin-block-start: 0;
          margin-block-end: 0;
          padding-inline-start: 0;
          padding: 0 !important;
          margin: 0 !important;
          border-left: none !important;
        }

        .footer-content__nav-item {
          padding: 0 !important;

          &:not(:last-of-type) {
            margin-right: 25px;
          }

          .footer-content__nav-link {
            cursor: pointer;
            color: #fff !important;
            font-size: 12px;
            text-decoration: none !important;

            &:focus,
            &:hover {
              color: #e3122a !important;
            }
          }

          .footer-content__nav-button {
            border: none;
            background: none;
            padding: 0 !important;
            font-size: 12px !important;
            height: auto !important;
            line-height: 1.15 !important;

            &:active {
              outline: none;
            }

            &:focus,
            &:hover {
              background: none;
              outline: none;
              color: #e3122a !important;
            }
          }
        }
      }
    }
  }
}
