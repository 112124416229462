.absolute-fill {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  top: 0;
}

.clams-dash_cont,
.new-claims-dash_cont,
.claims-dash_cont {
  font-size: 14px;
  font-weight: 400;
  z-index: 1;
  width: 100%;
  height: 100%;
  flex-direction: column;
  color: inherit;
}

.clams-dash_content {
  flex-direction: column;
  flex-grow: 1;
  // padding-top: 10px;
  color: inherit;
}

.clams-dash_content-inner {
  outline: 0;
  flex-grow: 1;
  overflow-y: auto;
  position: relative;
}

.clams-dash_content-overview {
  flex-direction: column;
  flex-shrink: 0;
  // background-color: rgba(248, 248, 248, 1);
  overflow-y: auto;
  padding: 0 0 16px;
  // padding: 16px 0 ;

  @media (max-width: 600px) {
    padding: 0 8px;
  }
}

.overview-region {
  width: 100%;
  flex-direction: column-reverse;

  @media (min-width: 900px) {
    flex-direction: row;
  }
}

.overview-right__region {
  // padding-left: 24px;
  min-width: 265px;
  flex-grow: 2;
  flex-basis: 0;
}

.overview-left__region {
  // padding-right: 24px;
  min-width: 256px;
  flex-grow: 1;
  flex-basis: 0;
}

.claims-dah_card-cta {

  @media (max-width: 600px) {
    flex-direction: column;
  }

  .claims-dah_card-cta-area {
    @media (max-width: 600px) {
      margin: 10px 0px;
    }
  }

  .separator {
    margin: 10px 10px;
    background: rgba(0, 0, 0, 0.1);
    width: 1px;

    @media (max-width: 600px) {
      width: 100%;
      height: 1px;
      margin: 10px 0px;
    }

  }

  .claims-dash_card-cta-create {
    @media (max-width: 600px) {
      margin: 40px 0px;
    }
  }
}



.claims-dah_card {
  flex-direction: column;
  border: 1px solid #ECECF9;
  box-shadow: 0 1.2px 0.2px 0 rgb(0 0 0 / 13%), 0 0.6px 1.8px 0 rgb(0 0 0 / 11%);
  border-radius: 8px;
  overflow: hidden;
  padding: 20px 0;
  background-color: rgba(255, 255, 255, 1);
  margin: 8px;
  flex-grow: 1;

  &.new-claim_card {
    margin: 0
  }

  @media screen and (max-width: 599px) {
    margin-right: 0;
    margin-left: 0;
  }
}

.claims-dah_card-content {
  overflow: auto;
  padding-left: 20px;
  padding-right: 20px;
  flex-grow: 1;

}

.claims-dah_card-cta-area {
  &.drag--active {
    opacity: 0.1;
    border: 1px solid rgba(200, 200, 200, 1) !important;
    border-radius: 3px;
  }
}

.claims-dah_card-cta_drag {
  background: #57D390;
  width: 66px;
  height: 66px;
  border-radius: 50%;
  align-items: center;
  justify-content: center;
  color: #fff;
}

.claims-dash_card-cta-create {

  &:hover,
  &:focus {
    color: rgb(0, 90, 158);
  }
}

.claims-dah_tab {
  display: flex;
  flex-direction: column;
  justify-items: flex-start !important;
  align-items: flex-start !important;
  text-align: left !important;
  padding: 8px 10px !important;


  &:active,
  &[data-active],
  &[aria-expanded=true] {
    background: #fff !important;
    color: rgb(0, 120, 212) !important;
  }

  .main-title {
    font-size: 14px;
    font-weight: 500;
  }

  .main-subtitle {
    font-size: 12px;
  }
}

.clash-head-row {
  background: rgba(255, 255, 255, 1) !important;
  position: relative;
  min-width: 100%;
  vertical-align: top;
  height: 32px;
  line-height: 32px;
  white-space: nowrap;
  box-sizing: content-box;
  padding-bottom: 1px;
  border-bottom: 1px solid rgba(200, 200, 200, 1) !important;
  cursor: default;
  user-select: none;
  font-size: initial;
  line-height: initial;
  border-color: rgba(234, 234, 234, 1) !important;
  min-height: 34px;

  th {
    font-size: 12px;
    font-weight: 400;
    color: rgba(51, 51, 51, 1);
    box-sizing: border-box;
    border: none;
    line-height: inherit;
    margin: 0;
    height: 32px;
    vertical-align: top;
    white-space: nowrap;
    text-overflow: ellipsis;
    outline: transparent;
    outline: transparent;
    text-align: left;
    height: auto;
    padding: 0;
    padding: 8px 8px;
    min-height: 32px;
    color: #9696B7;
    align-items: center;
    font-weight: 500;
  }
}

.clash-head-cell {
  display: inline-block;
  position: relative;
  box-sizing: border-box;
  padding: 11px 8px;
  min-height: 42px;
  vertical-align: top;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  outline: transparent;
  position: relative;
  background-color: inherit;
  margin: 0;
  border: none;
  min-height: inherit;
  // align-self: stretch;
  // flex-direction: row;
  min-height: 36px;
  // display: flex;
  align-items: center;
  padding: 0 8px !important;
  color: rgba(0, 0, 0, 1);
}

.claims-dah_table {
  table-layout: fixed;
  border-spacing: 0;

  tbody {

    tr {
      &:hover {
        background-color: rgba(0, 0, 0, .02);
      }
    }

    td {
      border-bottom: 1px solid rgb(200, 200, 200) !important;
      padding: 0 8px !important;
      border-color: rgb(234, 234, 234) !important;
      min-height: 34px;

      &:last-child {
        border-bottom: 0px;
      }
    }
  }
}

.primary_blue {
  color: rgba(0, 90, 158, 1) !important;

  &:hover {
    color: rgba(0, 90, 158, 1) !important;
  }
}

.claims-dah_card-header_sep {
  background-color: rgba(0, 0, 0, 0.1);
  height: 1px;
  margin: 10px 0px;
}

.activity-icon {
  align-self: center;
  display: inline-flex;
  height: 32px;
  width: 32px;
  background-color: rgb(244, 244, 244);
  padding: 8px;
  border-radius: 4px;
  color: #ADB0C3;
}

.response-message {
  padding: 4px 20px;

  &.draft {
    background: #ececec;
    padding: 2px 0px;

    .message {
      color: #0b1b34;
    }

    .reason {
      color: #0b1b34;
    }
  }

  &.awaiting-approval {
    background: #06509a;

    .message {
      color: #fff;
    }

    .reason {
      color: #fff;
    }
  }

  &.rejected {
    background: #FAE1E7;

    .message {
      color: #721C24;
    }

    .reason {
      color: #721C24;
    }
  }

  &.approved {
    background: #03d47c;

    .message {
      color: #fff;
    }

    .reason {
      color: #fff;
    }
  }

  &.reimbursed {
    background: #0b4112;

    .message {
      color: #fff;
    }

    .reason {
      color: #fff;
    }
  }

  .message {
    font-weight: 600;
    font-size: 12px;
  }

  .reason {
    font-weight: 500;
    font-size: 11px;

  }
}