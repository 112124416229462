.filters-area {
    border-bottom: 1px solid #ececec;
    padding: 5px 20px 0;
    position: relative;
    width: 100%;
}

.filters-triggers {
    margin-bottom: 10px;
}

.filters-trigger--button {
    border-color: transparent;
    color: #0185ff;
    text-decoration: none !important;
    cursor: pointer;
    font-weight: 600;
    font-size: 12px;
    line-height: inherit;
    margin: 0;
    padding: 0;
    vertical-align: baseline;

    &:hover {
        color: #7d8b8f;
    }

    &.reset {
        color: #7d8b8f;
        margin-left: 30px;

        &:hover,
        &:focus {
            color: #0185ff;
        }
    }
}

.filters-trigger--pagination {
    .page-count {
        color: #7d8b8f;
        font-size: 12px;
        margin-right: 8px;
        font-weight: 400;
    }

    .btn-group {
        position: relative;
        vertical-align: middle;
        gap: 0px;
    }

    .pagination-btn {
        background: #ececec;
        border-color: transparent;
        color: #0b1b34;
        padding: 4px 10px;
        border: 0px solid transparent;
        border-radius: 8px;

        &:last-child:not(:first-child) {
            border-top-left-radius: 0;
            border-bottom-left-radius: 0;
        }

        &:first-child:not(:last-child):not(.dropdown-toggle) {
            border-top-right-radius: 0;
            border-bottom-right-radius: 0;
        }

        &:hover,
        &:focus {
            background: #dfdfdf;
            border-color: transparent;
        }

        &.disabled,
        &[disabled] {
            cursor: not-allowed;
            opacity: .4;
            box-shadow: none;

        }
    }


}

.advanced-filters {
    transition: opacity .15s ease-in-out;
    transition-delay: 0s;
    opacity: 0;

    .advanced-filters--content {
        display: none;
    }

    &.open {
        transition: opacity .5s ease-in-out;
        transition-delay: .1s;
        opacity: 1;

        .advanced-filters--content {
            display: flex;
        }

    }

    .row {
        // padding-left: 35px;
        width: 100%;

        &>div {
            margin-bottom: 10px;
        }
    }

    .filters--row {
        .filters--col {
            flex-basis: 100%;
            min-height: 1px;
            padding-left: 15px;
            padding-right: 15px;

            @media (min-width: 768px) {
                flex-basis: 50%;
            }

            @media (min-width: 992px) {
                flex-basis: 25%;

                &.search {
                    flex-basis: 50%;
                }
            }
        }
    }

    .input-group {
        &:last-child {
            border-top-left-radius: 0;
            border-bottom-left-radius: 0;
        }
    }

    .input-group-addon {
        padding: 4px 10px;
        font-size: 13px;
        font-weight: 400;
        line-height: 1;
        color: #555;
        text-align: center;
        background-color: #fff;
        border: 1px solid #ececec;
        border-radius: 8px;
        white-space: nowrap;
        vertical-align: middle;
        color: #0b1b34;

        &:first-child {
            border-top-right-radius: 0;
            border-bottom-right-radius: 0;
        }

        &.search {
            &:last-child {
                border-top-left-radius: 0;
                border-bottom-left-radius: 0;
            }
        }
    }

    .input {
        border-radius: 8px;
        background-color: #ffffff;
        border: 1px solid #ececec;
        color: #0b1b34;
        padding: 5px 4px;
        line-height: 1.428571429 !important;
        width: 100% !important;
        font-size: 13px;

        &:focus-visible,
        &:active,
        &:visited,
        &:hover {
            border: 1px solid;
            border-color: rgb(0, 103, 181) !important;
            outline: none;
            box-shadow: none;
        }


        &:last-child {
            border-top-left-radius: 0;
            border-bottom-left-radius: 0;
        }

        &.search {
            &:first-child {
                border-top-right-radius: 0;
                border-bottom-right-radius: 0;
            }
        }
    }

    .checkbox {
        border-radius: 13px;
        cursor: pointer;
        float: left;
        height: 26px;
        line-height: 26px;
        margin: 4px 15px 4px 0;
        padding: 0;
        width: 120px;
        font-size: 12px;
        font-weight: 400;
        --checkbox-size: 14px !important;
        padding: 0 15px;
        border-color: transparent;

        &>* {
            font-size: 12px !important;
        }

        .chakra-checkbox__control {
            background: #fff;

            &[data-checked] {
                color: #fff;
                border-color: #3182ce;
                background: #3182ce;
            }
        }

        &.drafts {
            background: #ececec;
            color: #0b1b34;
        }

        &.awaiting {
            background: #06509a;
            color: #fff;
            width: 170px;
        }

        &.rejected {
            background: #fc3826;
            color: #fff;
        }

        &.approved {
            background: #03d47c;
            color: #fff;
        }

        &.reimbursed {
            background: #0b4112;
            color: #fff;
        }
    }

    td,
    th {
        text-align: left;
        font-weight: 400;
        vertical-align: middle;
    }

    .table thead th,
    .table-expenses-readonly thead th,
    .table-grouped thead th {
        background: 0 0;
        border-bottom: 1px solid var(--color-border);
        color: var(--color-heading);
        font-size: 10px;
        font-weight: 500;
        padding: 10px 15px;
        text-transform: uppercase;
        white-space: nowrap;
    }


}

.reports-container {
    padding: 10px 15px;
    padding-bottom: 0;

    &.draft-receipts {
        padding-top: 0px;
    }

    @media (min-width: 900px) {
        padding: 0 20px;
    }
}

.new-claim--container {
    padding: 10px 15px;
    padding-bottom: 20px;

    @media (min-width: 900px) {
        padding: 10px 20px;
        padding-bottom: 20px;
    }
}





// Tables
.table-cards {
    border-collapse: separate !important;
    border-spacing: 0 10px;

    tbody {
        tr {
            background: #fafafa;
            border-radius: 8px;
            // box-shadow: 0 8px 16px rgb(0 0 0 / 3%), 0 3px 6px rgb(0 0 0 / 4%);
            // box-shadow: 0 1px 2px 0 rgb(60 64 67 / 30%), 0 1px 3px 1px rgb(60 64 67 / 15%);

            &.selected {
                box-shadow: 0 0 0 2px #0185ff, 0 8px 16px rgb(0 0 0 / 3%), 0 3px 6px rgb(0 0 0 / 4%);
            }

            td {
                border-bottom: none;
                padding-bottom: 20px;
                padding-top: 20px;
            }
        }
    }
}

.table-reports {
    tr {
        th {
            border: none;
            color: #0b1b34;
            // font-size: 12px;
            font-size: 11px;
            font-weight: 600;
            text-transform: uppercase;
            white-space: nowrap;
            // padding: 10px 15px;
            padding: 0px 15px;
            padding-left: 7px;
            padding-right: 7px;

            &:hover {
                cursor: pointer;
            }

            &.checkbox {
                // padding: 9px 7px 9px 10px;
            }

            &.report-details {
                // padding: 3px 7px 3px 0;
                width: 25%;
            }

            &.total {
                // padding: 7px 17px 7px 4px;
                text-align: right;
            }

            &.to-be-reimbursed {
                width: 20%;
                text-align: right;
            }

            &.drafted {
                padding-left: 18px;
            }

            &.last-update {
                padding-left: 18px;
                text-align: right;
            }
        }

        td {
            padding: 15px 7px;
            border: none;

            &.checkbox {
                padding: 9px 7px 9px 10px;
            }

            &.report-details {
                font-size: 14px;
                line-height: 1.4;
                padding: 10px 7px 10px 0;
            }

            &.total {
                font-size: 19px;
                font-weight: 700;
                line-height: 1.4;
                padding-bottom: 17px;
                padding-top: 17px;
            }
        }


    }

    &.draft-receipts-table {
        tr {
            td {
                // padding: 0;
            }
        }

    }
}



.table-clickable {
    tr {
        td {
            cursor: pointer;
        }
    }
}