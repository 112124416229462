.home-view {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  top: 0;
  display: flex;
  flex-grow: 1;
  align-items: stretch;
}



.home-view--hed {
  // padding-left: 20px;
  // padding-right: 20px;
  // padding-top: 24px;
  // padding-bottom: 0;
  // margin-left: 72px;
  // margin-right: 72px;
  // z-index: 3;

  @media screen and (max-width: 599px) {
    padding-top: 16px;
    margin-left: 0;
    margin-right: 0;
    padding-left: 10px;
    padding-right: 10px;
  }
}


.home-tabs {
  padding: 0 92px 0 84px;
  padding-bottom: 6px;
  padding-top: 8px;


  @media screen and (max-width: 599px) {
    padding-top: 16px;
    padding-left: 10px;
    padding-right: 10px;
  }

  &.sticky {
    position: -webkit-sticky;
    position: sticky;
    box-shadow: rgb(0 0 0 / 8%) 0 1px 0;
    background: #FFF;
    z-index: 10;
    top: 0;
  }
}

.home-tabs_tab {
  height: 30px !important;
  text-decoration: none;
  cursor: pointer;
  background-color: transparent !important;
  color: rgba(0, 0, 0, .9) !important;
  margin-right: 0 !important;
  padding: 0 8px !important;
  text-align: center;
  position: relative;
  border: 0;
  overflow: visible;
  border-bottom: 2px solid;

  &[aria-selected=true] {
    font-weight: 600;
    border-color: rgba(0, 120, 212, 1) !important;
  }
}

.tab-text {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.home-content {
  padding-left: 92px;
  padding-right: 92px;
  padding-bottom: 16px;

  @media screen and (max-width: 599px) {
    padding-left: 10px;
    padding-right: 10px;
  }

}

.home-content_tabpanel {
  flex-direction: column;

  @media screen and (max-width: 599px) {
    flex-direction: column;
  }


}

.home-service_list {
  margin-bottom: 16px;
  margin-top: 16px;
}

.home-service_listitem {
  position: relative;
  transition: background-color .5s ease;
  border-radius: 4px;
  height: 120px;
  min-width: 264px;
  flex-basis: 264px;
  margin-right: 15px;
  margin-top: 15px;

  &:last-of-type {
    margin-right: 12px;
  }

  &:hover {
    background-color: rgba(0, 0, 0, .02);
  }

  @media screen and (max-width: 599px) {
    margin-right: 0;
    min-width: 100%;
    flex-basis: 100%;

    &:last-of-type {
      margin-right: 0;
    }
  }
}

.home-card-white {
  border: 1px solid #ECECF9;
  box-shadow: 0 1.2px 0.2px 0 rgb(0 0 0 / 13%), 0 0.6px 1.8px 0 rgb(0 0 0 / 11%);
  border-radius: 4px;
  overflow: hidden;
  padding: 0 !important;
  background-color: rgb(255, 255, 255);

  &:hover {
    background-color: rgba(0, 0, 0, .02);
  }

  &.card-table {
    &:hover {
      background-color: #FFF;
    }
  }
}

.home-service_link {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  overflow: hidden;
  padding: 20px;
  border-radius: 4px;
  text-decoration: none !important;
  color: rgba(0, 0, 0, .9);

  &:visited,
  &:hover {
    color: rgba(0, 0, 0, .9);
    text-decoration: none !important;
  }
}

.home-service_icon {
  width: 48px;
  height: 48px;
  flex-shrink: 0;

  img {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    color: white;
    user-select: none;
    border-radius: 4px;
    object-fit: cover;
    background-color: transparent;
  }
}

.home-service_details {
  margin-left: 16px;
  overflow: hidden;
}

.home-service_name {
  font-size: 14px;
  font-weight: 600;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  margin-bottom: 8px;
}

.home-service_description {
  text-overflow: ellipsis;
  font-size: 12px;
  display: -webkit-box;
  overflow: hidden;
  color: rgba(0, 0, 0, .55);
  -webkit-line-clamp: 4;
  -webkit-box-orient: vertical;
  line-height: 20px;
  max-height: 80px;
}

.card-table {
  margin-right: 12px;

  @media screen and (max-width: 599px) {
    margin-right: 0;
  }
}

.services-table {
  border-spacing: 0 0;
  color: rgba(0, 0, 0, .9);
  cursor: default;
  display: table;
  table-layout: fixed;
  user-select: none;
  border-collapse: separate;
  width: 100%;
  font-size: .875rem;
  overflow: hidden;
  border-collapse: unset;
}

.services-table-row {
  color: inherit;
  display: table-row;
  outline: none;
  text-decoration: none;
  transition: background 80ms linear;
  box-shadow: 0 1px 0 rgb(0 0 0 / 8%);

  &:hover {
    background-color: rgba(0, 0, 0, .02);
  }
}

.services-table-cell {
  border-top: 1px solid transparent;
  border-bottom: 1px solid transparent;
  color: inherit;
  display: table-cell;
  overflow: hidden;
  padding: 0;
  position: relative;
  text-align: left;
  text-decoration: none;
  white-space: nowrap;
  border-color: transparent !important;

  &:first-child {
    border-left: 2px solid transparent;
  }

  &:last-child {
    border-right: 1px solid transparent;
  }
}

.services-table-cell_content {
  padding: 19px;

  .home-service_link {
    padding: 0px;
  }
}

.home-activity-overview {
  margin-bottom: 16px;
  margin-top: 31px;
}

.home-activity-card {
  box-shadow: 0px 4px 4px rgba(43, 63, 95, 0.08);
  padding: 0 !important;
  overflow: hidden;
  background-color: rgba(255, 255, 255, 1);
  border: 1px solid #CDCDE1;
  border-radius: 4px;
  padding: 20px 0;
}

.home-activity-card-content {
  padding-left: 10px;
  padding-right: 10px;
  overflow: auto;
}

.home-activity-stats {
  width: 100%;
  height: fit-content;
  margin: 8px;
}

.home-activity-title_txt {
  font-size: 10px;
  font-weight: 600;
  letter-spacing: -.02em;
  word-break: break-word;
}

.metric-stat-item {
  &:first-child {
    margin-right: 14px;
  }
}

.metric-stat-icon {
  align-self: center;
  display: inline-flex;
  height: 32px;
  width: 32px;
  background-color: rgba(244, 244, 244, 1);
  padding: 8px;
  border-radius: 4px;
  color: #ADB0C3;
}

.home-btn {
  margin: 2px;

  &:first-child {
    margin-right: 4px;
  }
}

// New Styles
.home-view--hed {
  z-index: 3;

  @media (max-width: 899px) {
    padding: 15px;
    padding-bottom: 0;
  }

  @media (min-width: 900px) {
    padding: 20px 92px;
    padding-bottom: 0;
  }

  .heading {
    font-size: 20px;
    line-height: 28px;
    font-weight: 600;
    color: #0b1b34;

    @media (max-width: 899px) {
      font-size: 20px;
    }
  }

  .home-menubar {

    .home-menubar--btn {
      margin-bottom: 0;
      font-weight: 700;
      text-align: center;
      vertical-align: middle;
      cursor: pointer;
      background-image: none;
      border: 1px solid transparent;
      white-space: nowrap;
      padding: 4px 10px;
      font-size: 14px;
      line-height: 1.428571429;
      border-radius: 8px;
      line-height: 22px;

      &:not(:last-child) {
        margin-right: 5px;
      }

      &.leave {
        background: #dfdfdf;
        color: #0b1b34;

        &:active,
        &:hover {
          background: #cccbcb;
          border-color: transparent;
        }

        &:focus {
          box-shadow: 0 0 0 2px rgb(185 182 182 / 50%);
        }
      }

      &.claim {
        background: #16ec93;
        color: #fff;
        text-decoration: none;

        &:active,
        &:hover {
          background: #03d47c;
          text-decoration: none;
        }

        &:focus {
          background: #03d47c;
          border-color: transparent;
          box-shadow: 0 0 0 2px rgb(4 190 113 /50%);
          color: #fff;
        }
      }
    }
  }
}

.home-tabs {
  @media (max-width: 899px) {
    padding: 15px;
    padding-bottom: 0;
  }

  @media (min-width: 900px) {
    padding: 20px 92px;
    padding-bottom: 0;
  }

  .tab-text {
    font-size: 14px;
    font-weight: 600;
    line-height: 1.4;
    color: #0b1b34;
    letter-spacing: -.02em;
    margin: 0;

    @media (max-width: 899px) {
      font-size: 15px;
    }
  }
}

.home-content {

  @media (max-width: 899px) {
    padding: 15px;
    padding-bottom: 0;
  }

  @media (min-width: 900px) {
    padding: 20px 92px;
    padding-bottom: 0;
  }

  .home-content_tabpanel {
    @media (max-width: 899px) {
      flex-direction: column;
    }

    @media (min-width: 900px) {
      flex-direction: column;
    }
  }
}

.serviceList {
  flex-direction: column;
  width: 100%;

  @media (max-width: 899px) {
    flex-basis: 100%;
  }

  @media (min-width: 900px) {
    flex-basis: 50%;
  }

  .serviceCard {
    border-radius: 8px;
    border: 1px solid #ECECF9;
    box-shadow: 0 1.2px 0.2px 0 rgb(0 0 0 / 13%), 0 0.6px 1.8px 0 rgb(0 0 0 / 11%);
    transition: all .2s ease-out, margin .2s ease-out;
    opacity: 1;
    background: #fff;
    margin: 0 auto;
    position: relative;
    width: 100%;
    margin-bottom: 15px;

    .service-card--header {
      cursor: pointer;
      height: 70px;
      padding: 10px;
      display: flex;
      align-items: center;

      .service-card--header__icon {
        float: left;
        height: 40px;
        margin-right: 10px;
        position: relative;
        width: 40px;

        .icon {
          border-radius: 8px;
          transition: all .25s cubic-bezier(.175, .885, .32, 1.275);
          background: var(--color-icon);
          height: 100%;
          overflow: hidden;
          position: relative;
          width: 100%;
          z-index: 9;

          img {
            max-width: 100%;
          }
        }
      }
    }
  }
}

.service-card--meta {
  // float: left;
  font-size: 13px;
  // position: relative;
  // width: calc(100% - 55px);

  .service-card--meta_service {
    transition: top .25s ease-in-out;
    color: #0b1b34;
    font-size: 16px;
    font-weight: 700;
    // left: 0;
    // position: absolute;
    // top: 1px;
  }

  .service-card--meta_desc {
    transition: top .25s ease-in-out, opacity .25s ease-in-out;
    left: 0;
    overflow: hidden;
    // position: absolute;
    text-overflow: ellipsis;
    // top: 20px;
    white-space: nowrap;
    width: 100%;
    color: #0b1b34;
  }
}

.timelineList {
  flex-direction: column;
  width: 100%;

  @media (max-width: 899px) {
    flex-basis: 100%;
  }

  @media (min-width: 900px) {
    flex-basis: 50%;
  }
}

.timelineCard {
  border-radius: 8px;
  border: 1px solid #ECECF9;
  box-shadow: 0 1.2px 0.2px 0 rgb(0 0 0 / 13%), 0 0.6px 1.8px 0 rgb(0 0 0 / 11%);
  transition: all .2s ease-out, margin .2s ease-out;
  opacity: 1;
  background: #fff;
  margin: 0 auto;
  position: relative;
  width: 100%;
}

.statHeader {
  font-weight: 700;
  line-height: 1.4;
  color: #0b1b34;
  font-size: 16px;
  border-bottom: 1px solid #fafafa;
  border-color: #E2E8F0;

  @media (max-width: 899px) {
    padding: 10px;
  }

  @media (min-width: 900px) {
    padding: 10px;
  }
}

.statBody {
  @media (max-width: 899px) {
    padding: 10px;
  }

  @media (min-width: 900px) {
    padding: 10px;
  }

  .stat {
    padding: 10px;
  }

  .statLabel {
    font-size: 14px;
    font-weight: 600;
    line-height: 1.4;
    color: #0b1b34;
    margin-bottom: 10px;
  }

  .statNumber {
    font-size: 24px;
    font-weight: 700;
    line-height: 1.4;
    color: #0b1b34;
  }
}

.expense_row-no_inner {
  min-height: 130px;
  width: 100%;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  font-size: 0.75rem;
  font-weight: 400;
  line-height: 1.5rem;
  letter-spacing: 0.00625em;
}

.expense_row-no_text {
  font-size: 13px;
  line-height: 22px;
  margin-bottom: 5px;
  font-weight: 500;
  color: #0b1b34;

  &.error {
    color: #ec1703
  }

  &.info {
    color: #0b1b34;
  }
}

.expense_row-no_data--btn {
  transition: none;
  border-width: 1px;
  font-size: 13px;
  line-height: 22px;
  font-weight: 700;
  text-align: center;
  vertical-align: middle;
  touch-action: manipulation;
  border: 1px solid transparent;
  text-decoration: none;
  white-space: nowrap;
  padding: 4px 10px;
  border-radius: 8px;

  &.add {
    background: #1577da;
    color: #fff;

    &:active,
    &:hover {
      background: #06509a;
      text-decoration: none;
    }

    &:focus {
      background: #06509a;
      border-color: transparent;
      box-shadow: 0 0 0 2px rgb(7 77 146 / 50%);
      color: #fff;
      text-decoration: none;
    }
  }

  &.success {
    background: #dfdfdf;
    color: #0b1b34;
    text-decoration: none;

    &:active,
    &:hover {
      background: #cccbcb;
      border-color: transparent;
      text-decoration: none;
    }

    &:focus {
      box-shadow: 0 0 0 2px rgb(185 182 182 / 50%);
      text-decoration: none;
    }
  }
}

.page-top-three {
  display: flex;
  flex-wrap: wrap;
  list-style-type: none;
  margin-top: 20px;
  padding-left: 0;
  flex-direction: column;
  align-items: center;

  @media (min-width: 846px) {
    display: flex;
    flex-wrap: wrap;
    list-style-type: none;
    margin-top: 20px;
    padding-left: 0;
    flex-direction: row;
    align-items: left;
  }

  .page-top-three--item {
    @media (min-width: 374px) {
      width: 300px;
      margin-right: 20px;
    }

    @media (min-width: 846px) {
      width: 240px;
      margin-right: 16px;
    }

    @media (min-width: 1199px) {
      // width: 333px;
      width: 380.333333px;
    }

    margin-bottom: 36px;
    width: 280px;
  }

  .page-top-three--image {
    background: #f2f2f2;
    // width: 280px;
    // height: 168px;
    width: 100%;
    height: 100%;

    @media (min-width: 374px) {
      // width: 300px;
      // height: 180px;
    }

    @media (min-width: 846px) {
      // width: 240px;
      // height: 144px;
    }

    @media (min-width: 1199px) {
      // width: 333px;
      // height: 200px;
    }
  }
}

.home-titles {
  display: flex;
  align-items: center;
  font-weight: 600;
  font-size: 14px;
  color: #0b1b34;
  margin-bottom: 16px;
  min-width: 300px;

  @media (min-width: 300px) {
    min-width: 100%;
  }

}

.home-usefulLinks {
  flex-wrap: wrap;
  flex-direction: column;
  padding-left: 92px;
  padding-right: 92px;
  padding-bottom: 16px;

  @media (min-width: 480px) {
    min-width: 300px;
  }

  @media (min-width: 920px) {
    flex-direction: row;
    padding: 20px 92px;
    padding-bottom: 0;
  }

  .home-usefulLinks--more {
    flex: 0 0 auto;
    max-width: 789px;
    margin-bottom: 40px;

    @media (min-width: 920px) {
      flex: 3 3 calc(3*(253px + 10px));
    }

    .home-titles {
      min-width: 170px;
    }
  }

  .home-usefulLinks--moreContent {
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 17px;
    margin-left: -12px;
    margin-right: 12px;

    .home-usefulLinks--moreSection {
      flex: 0 0 calc(253px + 10px - 12px);
      margin-left: 12px;

      a {
        color: #0078d4;
        font-size: 12px;

        &:hover {
          text-decoration: underline;
        }
      }

    }
  }

  .home-usefulLinks--appLinks {
    flex: 0 0 auto;

    @media (min-width: 920px) {
      flex: 1 1 calc(253px - 12px);
    }


  }
}

.homepage-section {
  &:not(:last-child) {
    margin-bottom: 40px;
  }

  @media (min-width: 480px) {
    min-width: 300px;
  }

  display: flex;
  flex-wrap: wrap;

  .homepage-section--container {
    padding-left: 0;
    list-style: none;
    margin-top: -7px;
    margin-left: -7px;
    display: flex;
    flex-wrap: wrap;


    @media (min-width: 1211px) {
      justify-content: space-between;
    }

    @media (min-width: 1259px) {
      justify-content: space-between;
    }

    &.claims-activity-row {
      width: 100%;
    }
  }

  .homepage-section--inner {
    height: 100px;
    width: 100px;
    flex: 0 0 auto;
    cursor: pointer;
    text-align: center;
    border: 1px solid transparent;
    margin: 0 0 0 7px;
    box-sizing: border-box;

    &:hover {
      border-radius: 8px;
      border: 1px solid #ECECF9;
      box-shadow: 0 1.2px 0.2px 0 rgba(0, 0, 0, 0.13), 0 0.6px 1.8px 0 rgba(0, 0, 0, 0.11);
      background: #fff;
      // transition: all 0.2s ease-out, margin 0.2s ease-out;
    }

    &.claims-activity-cell {
      cursor: auto;

      &:hover {
        border-radius: none;
        border: 0px solid transparent;
        box-shadow: none;
        background: #fff;
        transition: none;
      }

      &.claims-table {
        flex-grow: 1;
      }

      &.claims-stats,
      &.claims-table {
        height: auto;
        width: auto;
      }

      &.claims-stats {
        flex-basis: 20%
      }

      .homepage-section--card-hed {
        background: #F7F8FB;
        padding: 2px 10px;
        width: 100%;
        flex-shrink: 0;
      }
    }

    .homepage-section--link {
      height: 100%;
      width: 100%;
      display: flex;
      align-items: center;
      flex-direction: column;
      justify-content: center;
      color: #0b1b34;

      .homepage-section--link_icon {
        flex: 0 0 auto;
        height: 32px;
        width: 32px;
        margin-bottom: 9px;
      }

      .homepage-section--link_text {
        max-width: 100%;
        display: -webkit-box;
        display: block;
        display: -webkit-box;
        line-height: 17px;
        font-size: 13px;
        max-height: 34px;
        overflow: hidden;
        text-overflow: ellipsis;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
      }
    }
  }
}

.homepage-section-claims--row {
  @media (max-width: 998px) {
    flex-direction: column;
  }

  .homepage-section-claimsstats--cell {
    @media (max-width: 998px) {
      width: 100%;
      margin-bottom: 20px;
    }
  }
}

.claims-summary-view--all {
  margin-bottom: 0;
  font-weight: 600;
  text-align: center;
  vertical-align: middle;
  cursor: pointer;
  background-image: none;
  border: 1px solid transparent;
  white-space: nowrap;
  padding: 3px 10px;
  font-size: 12px;
  line-height: 1.428571429;
  border-radius: 8px;
  line-height: 18px;
  color: #ffffff !important;
  background: #0185ff;
  text-decoration: none !important;

  &:hover {
    background: #0680f1;
    border-color: transparent;
    color: #fff !important;
    text-decoration: none !important;
  }
}