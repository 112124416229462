.services-dropdown {

    @media only screen and (max-width: 505px) {
        height: calc(100vh - 41px - 60px);
    }

    @media only screen and (min-width: 620px) {
        max-width: 900px;
        position: absolute;
        top: 40px;
        left: 0;
        height: calc(100vh - 40px - 28px - 120px);
        right: auto;
        min-width: 225px;
        width: 550px;
    }

    background: #232f3e;
    z-index: 1;
    bottom: 29px;
    // bottom: 67px;
    right: 0;
    left: 0;
    top: 40px;
    box-shadow: 2px 2px 4px rgb(0 0 0 / 40%),
    inset 0px 1px 0px #414750 !important;
    position: fixed;
    max-height: none;
    box-sizing: border-box !important;
    margin-top: -1px;
    margin-bottom: -1px;
}

.services-dropdown__menu {
    height: 100%;
    display: flex;
    flex-direction: column;

    .services-dropdown__menu--inner {
        height: 100%;
        display: flex;
        overflow: auto;
        position: relative;
        flex-grow: 1;
        box-sizing: border-box;
        flex-direction: column;
        justify-content: normal;
    }

    .services-dropdown__menu--hed {
        color: #D5DBDB;
        margin: 0;
        padding: 12px 20px;
        font-size: 18px;
        line-height: 2;
        border-bottom: 1px solid #414750;

        @media (min-width: 620px) {
            display: none;
        }
    }
}

.services-dropdown__menu-content--wrapper {
    padding: 16px 0;

    .services-dropdown__menu-content--cont {
        // margin-top: 23px;

        @media (max-width: 619px) {
            margin-top: 10px;
            margin-left: 0 !important;
        }
    }
}

.services-dropdown__menu-content--list {
    margin: 0 10px;
    display: flex;
    padding: 0;
    flex-wrap: wrap;

    @media (max-width: 619px) {
        margin: 0 10px;
    }
}

.services-dropdown__menu-content--listitem {
    padding-bottom: 0px !important;
    padding-top: 0px !important;
    margin-bottom: 20px;
    margin-right: 0;
    min-width: 0;
    min-height: 0;
    display: flex;
    align-items: flex-start;
    flex-wrap: wrap;
    width: 100%;
}

.services-dropdown__menu-content--favorite {
    display: flex;
    align-items: center;
    justify-content: center;
    align-self: flex-start;
    margin-top: 2px !important;
    cursor: pointer;
    margin: 0;
    outline: none;
    padding: 0;
    background: none;
    box-sizing: border-box;
    flex-shrink: 0;
    border-color: transparent;
    border-style: solid;
    border-width: 1px;

    @media (hover: hover),
    screen and (-ms-high-contrast: active),
    (-ms-high-contrast: none) {
        opacity: 0;
    }
}

.services-dropdown__menu-content--link {
    margin-left: 4px;
    box-sizing: content-box !important;
    padding: 0 !important;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    flex: 1;
    text-decoration: none !important;
    letter-spacing: normal !important;
    color: #fff;
    width: 100%;
    cursor: pointer;
    outline: none;
    box-sizing: border-box;
    border-color: transparent;
    border-style: solid;
    border-width: 1px;
    text-decoration: none;
    text-transform: capitalize;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0) !important;

    &:hover {
        color: #e3122a !important;
    }

    &.active {
        color: #e3122a !important;
    }

    &.taf,
    &.pdg {
        h3 {
            text-transform: uppercase;
        }
    }

    h3 {
        font-size: 18px;
        line-height: 22px;
        white-space: normal;
        overflow-wrap: break-word;
        text-align: left;
        box-sizing: content-box !important;
        text-size-adjust: auto !important;
        letter-spacing: normal !important;
        margin: 0;
        padding: 0;
        font-weight: 500;
        color: inherit;
        cursor: inherit;
    }

    p {
        width: 100%;
        font-size: 12px;
        line-height: 16px;
        white-space: normal;
        overflow-wrap: break-word;
        text-align: left;
        box-sizing: content-box !important;
        text-size-adjust: auto !important;
        letter-spacing: normal !important;
        color: #d5dbdb;
        margin: 4px 0 0;
        padding: 0;
        cursor: inherit;
    }
}
