.expenses-dashboard--head {
    padding: 15px 20px;
    flex-shrink: 0;
    border-bottom: 1px solid #ececec;

    .heading {
        font-size: 20px;
        font-weight: 700;
        color: #0b1b34;
        line-height: 1.4;
        margin-bottom: 0;

    }

    .action--button {
        transition: none;
        border-width: 1px;
        font-size: 13px;
        line-height: 22px;
        font-weight: 700;
        text-align: center;
        vertical-align: middle;
        touch-action: manipulation;
        border: 1px solid transparent;
        white-space: nowrap;
        padding: 4px 10px;
        border-radius: 8px;
        outline: none;

        &.new-claim {
            background: #03bb6d;
            border-color: transparent;
            color: #fff;
        }

        &.submit {
            background: #03bb6d;
            border-color: transparent;
            color: #fff;
        }

        &.add-receipt {
            background: #03bb6d;
            border-color: transparent;
            color: #fff;
        }

        &.save-group {
            background: #0185ff;
            border-color: transparent;
            color: #fff;

            &:hover,
            &:active {
                background: #0680f1;
                text-decoration: none;
                border-color: transparent;
            }

            &:focus,
            &:focus-visible {
                background: #0680f1;
                text-decoration: none;
                border-color: transparent;
                box-shadow: 0 0 0 2px rgb(6 128 241 / 50%);
            }
        }

        &:hover,
        &:active {
            background: #03bb6d;
            text-decoration: none;
            border-color: transparent;
        }

        &:focus,
        &:focus-visible {
            background: #03bb6d;
            text-decoration: none;
            border-color: transparent;
            box-shadow: 0 0 0 2px rgb(4 190 113 / 50%);
        }
    }
}