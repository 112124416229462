.receipts--action-area {
  .receipts--action {
    transition: none;
    border-width: 1px;
    font-size: 11px;
    line-height: 22px;
    // font-weight: 700;
    text-align: center;
    vertical-align: middle;
    touch-action: manipulation;
    border: 1px solid transparent;
    white-space: nowrap;
    padding: 4px 10px;
    border-radius: 8px;
    outline: none;

    &.is-left {
      margin-right: 5px;
    }

    &.attach {
      background: #16ec93;
      color: #fff;
      text-decoration: none;

      &:active,
      &:hover {
        background: #03d47c;
        text-decoration: none;
      }

      &:focus,
      &:focus-visible {
        background: #03d47c;
        border-color: transparent;
        box-shadow: 0 0 0 2px rgb(4 190 113 /50%);
        color: #fff;
      }
    }

    &:hover {
      text-decoration: none;
      background: #5C5F7A;
    }

    &.delete {
      background: #fc3826;
      color: #fff;

      &:active,
      &:hover {
        background: #ec1703;
        border-color: transparent;
        color: #fff;
      }

      &:focus,
      &:focus-visible {
        background: #ec1703;
        border-color: transparent;
        box-shadow: 0 0 0 2px rgb(252 56 38 / 50%);
        color: #fff;
      }
    }

    &.view {
      background: #ececec;
      color: #0b1b34;

      &:active,
      &:hover {
        background: #ececec;
        text-decoration: none;
      }

      &:focus,
      &:focus-visible {
        background: #ececec;
        border-color: transparent;
        box-shadow: 0 0 0 2px rgb(218 216 216/ 50%);
      }
    }

    &.edit {
      background: #1577da;
      color: #fff;

      &:active,
      &:hover {
        background: #06509a;
        text-decoration: none;
      }

      &:focus,
      &:focus-visible {
        background: #06509a;
        border-color: transparent;
        box-shadow: 0 0 0 2px rgb(7 77 146 / 50%);
        color: #fff;
      }
    }
  }
}



.receipts_dash-head_sep {
  flex: 0 1 auto;
  align-self: center;
  width: 1px;
  min-width: 1px;
  height: 24px;
  background-color: rgba(200, 200, 200, 1);
  margin: 0 8px 0 8px;
}

.receipts_dash-head_filters {
  font-size: 14px;
  font-weight: 400;
  color: rgba(51, 51, 51, 1);
  position: relative;
  background-color: rgba(244, 244, 244, 1);
  height: 40px;
  white-space: nowrap;
  user-select: none;
  height: 48px;
  padding-top: 8px;
  padding-bottom: 8px;
  // overflow: auto;
  flex: 1 1 auto;
  background-color: transparent;
  width: 100px;
}

.receipts_dash-head_filters-menubar {
  // overflow: hidden;
  flex: 1 1 auto;
  vertical-align: top;
  line-height: 40px;
  max-width: 100%;
  margin: 0 20px;
  margin-left: 0;
}

.receipts_dash-head_filters-side {
  flex: 0 0 auto;
  padding-right: 0;
  align-items: center;


  .receipts_dash-head_filters-side-btn {
    border-radius: 4px;

    &[disabled],
    &:disabled {
      background: #fff !important;
      cursor: not-allowed;
      pointer-events: all !important;
    }

    &:hover {
      background-color: rgba(234, 234, 234, 1);
      color: rgba(51, 51, 51, 1);
    }
  }
}

.receipts_dash-head_filters-side-btn {
  font-size: 14px;
  font-weight: 400;
  color: rgba(51, 51, 51, 1);
  position: relative;
  background: none;
  background-color: transparent;
  border: none;
  line-height: 40px;
  min-width: 20px;
  text-align: center;
  padding: 0 4px;
  display: block;
  height: 100%;
  cursor: pointer;
  outline: transparent;
  position: relative;
  padding: 0 8px;
  line-height: 32px;
  margin: auto;
  height: 32px;
  color: rgba(0, 120, 212, 1);
}

.receipts_dash-head_filters-side-select {
  font-size: 14px;
  font-weight: 400;
  position: relative;
  border: none;
  text-align: center;
  padding: 0 4px;
  display: block;
  height: 100%;
  cursor: pointer;
  outline: transparent;
  position: relative;
  line-height: 32px;
  margin: auto !important;
  height: 32px !important;
  border-color: rgba(234, 234, 234, 1) !important;
  border-radius: 4px !important;

  &:focus-visible,
  &[data-focus-visible] {
    border-color: rgba(234, 234, 234, 1) !important;
    box-shadow: none !important;
  }

  &:hover,
  &:active,
  &:focus {
    color: rgba(51, 51, 51, 1) !important;
    box-shadow: none !important;
  }
}

.receipts-list__action_count {
  height: 1.2rem;
  width: 1.2rem;
  background: #DEDFF3;
  justify-content: center;
  align-items: center;
  margin-left: 4px;
  border-radius: 50%;
  font-weight: 700;
  font-size: 12px;
  color: #408DFB;
}