.btn-group {
  gap: 8px;
  margin-left: 16px;
  padding-right: 4px;
}

.btn {
  align-items: center;
  border-radius: 2px;
  border: 1px solid transparent;
  cursor: pointer;
  display: inline-flex;
  flex-direction: row;
  flex-shrink: 0;
  font-family: inherit;
  font-size: inherit;
  font-weight: 600;
  justify-content: center;
  line-height: inherit;
  outline: none;
  overflow: visible;
  padding: 6px 12px;
  position: relative;
  transition: background 80ms linear;
  height: auto;
  border-radius: 3px;
  font-size: 13px;
  text-decoration: none;

  &.primary {
    background-color: rgba(0, 120, 212, 1);
    color: rgba(255, 255, 255, 1);
    text-decoration: none;

    &:hover {
      background-color: rgba(0, 103, 181, 1);
      text-decoration: none;
    }
  }

  &.primary-light {
    background: rgba(65, 141, 251, 0.2);
    color: rgba(0, 120, 212, 1);
    text-decoration: none;

    &:hover {
      background-color: rgba(0, 120, 212, 1);
      color: rgba(255, 255, 255, 1);
      text-decoration: none;
    }
  }

  &.default {
    background-color: rgba(0, 0, 0, .06);
    color: rgba(0, 0, 0, .9);
    text-decoration: none;

    &:hover {
      background-color: rgba(0, 0, 0, .1);
      text-decoration: none;
    }
  }

  &:not(.icon-only)>.icon-left {
    font-weight: normal;
    // padding-right: 10px;
  }

  &.success {
    background: #16ec93;
    color: #fff;
    text-decoration: none;

    &:hover {
      background: #03d47c;
      text-decoration: none;
    }
  }

  &.secondary {
    background: #1577da;
    color: #fff;

    &:hover {
      background: #06509a;
      text-decoration: none;
    }
  }
}