.expenses-dashboard--content {
    outline: 0;
    flex-grow: 1;
    overflow-y: auto;
    position: relative;

    .content-area {
        width: 100%;
        position: absolute;
        left: 0;
        top: 0;
    }

    .content-area---inner {
        position: relative;
        background: rgba(255, 255, 255, 1);
        color: initial;
        background-color: inherit;
        cursor: default;
    }
}