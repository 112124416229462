.sidebar-layout__main-content--scrollable {
  div[wp-sidebar-main-layout-region='content'] {
    padding-bottom: 2rem;
  }
}

#wp-widget--list {
  margin-right: -20px;
  margin-bottom: -20px;

  // Small devices (landscape phones, 576px and up)
  @media only screen and (max-width: 576px) {
    margin-right: 0px;
    margin-bottom: 0px;
  }

  &.wp-dash--top__activity {
    @media only screen and (max-width: 778px) {
      flex-direction: column-reverse;
    }
  }
}

.widget--list {
  margin: 0;
  display: flex;
  padding: 0;
  flex-wrap: wrap;
  margin-right: -20px;
  margin-bottom: -20px;

  @media only screen and (max-width: 778px) {
    flex-direction: column-reverse;
  }

  &[dash-layout-region='expense_report'] {
    margin-top: 20px;
  }
}

.widget--listitem {
  margin: 0px 20px 20px 0px;

  &.dashboard--pending_tasks {
    width: 33%;

    @media only screen and (max-width: 778px) {
      width: 100%;
    }
  }

  .widget {
    margin-bottom: 0px;
  }
}

.widget--content {
  height: 100%;
  border-collapse: separate;
  border-spacing: 0;
  caption-side: top;
  cursor: auto;
  direction: ltr;
  empty-cells: show;
  font-family: serif;
  font-size: medium;
  font-style: normal;
  font-feature-settings: normal;
  font-variant: normal;
  font-stretch: normal;
  line-height: normal;
  hyphens: none;
  letter-spacing: normal;
  list-style: disc outside none;
  tab-size: 8;
  text-align: left;
  text-align-last: auto;
  text-indent: 0;
  text-shadow: none;
  text-transform: none;
  visibility: visible;
  white-space: normal;
  widows: 2;
  word-spacing: normal;
  box-sizing: border-box;
  font-size: 14px;
  color: #16191f;
  font-weight: 400;
  display: flex;
  flex-flow: column nowrap;
  word-wrap: break-word;
  box-shadow: 0 1px 1px 0 rgb(0 28 36 / 30%), 1px 1px 1px 0 rgb(0 28 36 / 15%), -1px 1px 1px 0 rgb(0 28 36 / 15%);
  border-top: 1px solid #eaeded;
  border-radius: 0px;
  box-sizing: border-box;
  background-color: #ffffff;
  border-radius: 4px;
}

.widget--header {
  flex: 0 0 auto;
  background-color: #f7f8fb;
  border-top-left-radius: 0px;
  border-top-right-radius: 0px;

  &:not(:empty) {
    border-bottom: 1px solid #eaeded;
  }

  .widget--hed_inner {
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    justify-content: flex-start;
    outline-color: #0073bb;
    user-select: none;
  }

  .widget--hed_heading {
    overflow: hidden;
    white-space: nowrap;

    h1 {
      margin: 0;
      text-decoration: none;
      padding: 4px 0;
      overflow: hidden;
      text-overflow: ellipsis;
      margin-right: 8px;
      color: #16191f;
      font-size: 15px;
      line-height: 22px;
      letter-spacing: normal;
      font-weight: 700;
    }
  }
}

.widget--hed {
  padding: 12px 20px;
}

.widget--body {
  flex: 1 0 auto;

  .widget--body_inner {
    height: 100%;
    padding: 16px 20px 20px;
    position: relative;
    box-sizing: border-box;
    flex-direction: column;
    display: flex;
    flex: 1;

    &.report__summary--body {
      padding-top: 0px;
    }
  }
}

.widget--body_notice {
  border-bottom: 1px solid #eaeded;
  font-size: 14px;
  line-height: 22px;
  color: #16191f;
  font-weight: 500;
  margin-bottom: 16px;
}

.widget--body_notice-hed {
  font-size: 14px;
  line-height: 22px;
  color: #16191f;
  font-weight: 500;
}

.text-error {
  color: #e3122a !important;
}

.claims-quick__sub {
  display: flex;
  flex-wrap: wrap;
  height: 100%;

  & > * {
    border-left: 1px solid #eaeded;
    padding: 0 2rem;
    margin: 2rem 0;
    flex: 1 0 50%;
    max-width: 50%;
  }

  & > :nth-child(3n + 1) {
    border-left-width: 0;
  }

  .claims-quick__sub--create {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;

    .claims-quick__sub--create-inner {
      display: flex;
      flex-direction: row;
      align-items: center;

      .claims-quick__sub--create-icon {
        margin-right: 10px;

        svg {
          width: 28px;
          height: 28px;

          @media only screen and (max-width: 560px) {
            width: 20px;
            height: 20px;
          }

          * {
            stroke: currentColor;
            stroke-width: 2px;
          }
        }
      }

      h1 {
        font-size: 1.8rem;
        line-height: 2rem;
        color: #16191f;
        font-weight: 500;

        @media only screen and (max-width: 560px) {
          font-size: 1.2rem;
        }
      }
    }
  }

  .claims-quick__sub--upload {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    height: 100%;

    .claims-quick__sub--upload-area {
      width: 66px;
      height: 66px;
      background: #57d390;
      border-radius: 50%;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    .claims-quick__sub--upload-cta {
      text-align: center;
      margin-top: 10px;

      h3 {
        font-weight: 700;
        font-size: 13px;
        line-height: 25px;
        color: #2e3151;

        @media only screen and (max-width: 560px) {
          font-size: 12px;
        }
      }

      p {
        font-weight: 500;
        font-size: 14px;
        line-height: 20px;
        color: #408dfb;

        @media only screen and (max-width: 560px) {
          font-size: 12px;
        }
      }
    }
  }
}

.widget--body_tabs {
  margin: 0;
  padding: 0;
  display: flex;
  border-bottom: 1px solid #aab7b8;

  .widget--body_tabs--list {
    margin: 0;
    padding: 0;
    display: flex;
    overflow-x: scroll;
    overflow-y: hidden;
    position: relative;
    width: 100%;
  }

  .widget--body_tabs--listitem {
    list-style: none;
    padding: 0;
    flex-shrink: 0;
    display: flex;
    max-width: calc(90% - 20px);

    &:not(#\9):first-child {
      margin-left: 1px;
    }
  }
}

.tab-button {
  position: relative;
  display: flex !important;
  align-items: stretch !important;
  flex-direction: column !important;
  justify-content: flex-start !important;
  text-decoration: none;
  cursor: pointer;
  padding: calc(12px - 1px) 0 12px 0 !important;
  margin-top: 1px;
  margin-bottom: 0px; // border: 1px solid transparent;
  font-size: 14px !important;
  line-height: 22px;
  font-weight: 700;
  color: #2e3151;

  &[aria-selected='true'],
  &[data-selected] {
    --tabs-color: #408dfb !important;
    border-color: currentColor !important;
    border-bottom-width: 3px !important;
  }

  &:active,
  &[data-active] {
    --tabs-bg: #fff !important;
  }

  &:first-child {
    span {
      padding: 4px 20px 4px 0px;
    }
  }

  &:last-child {
    span {
      border: 0px;
    }
  }

  span {
    position: relative;
    display: flex !important;
    align-items: stretch !important;
    flex-direction: column !important;
    align-items: center;
    padding: 4px 20px;
    text-align: left;
    min-width: 0;
    word-break: break-word;
    margin-right: calc(-1 * 1px);
    border-right: 1px solid #aab7b8;

    h3 {
      font-weight: 600;
      font-size: 14px;
      line-height: 16px;
    }

    p {
      font-weight: 500;
      font-size: 13px;
      margin-top: 10px;
      line-height: 17px;
    }
  }
}

.widget--footer {
  flex: 0 0 auto;
  padding: 12px 20px;
  border-top: 1px solid #eaeded;

  &.table--pagination {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .widget--footer_link {
    display: flex;

    a {
      color: #408dfb;
      font-weight: 500;
      font-size: 13px;
      line-height: 16px;
    }
  }
}

.widget--tabpanel {
  padding: 0px !important;
  outline: 0px solid transparent !important;
  outline-offset: 0px !important;
}

.widget--table {
  overflow-x: auto;
  position: relative;
  width: 100%;

  & > table {
    min-width: 100%;
    color: #16191f;
    border-spacing: 0;
    background-color: #fff;
    table-layout: fixed;
  }

  thead > tr > * {
    text-align: left;
    padding: 0.3rem 1rem;
    border-bottom: 1px solid #ececf9;
    box-sizing: border-box;
    min-height: 4rem;
    background: #fafafa;
    word-break: keep-all;
  }

  &.report__summary--table {
    thead > tr > * {
      background: #fff;
    }
  }

  thead > tr > th {
    position: relative;
  }

  thead > tr > [style*='width:'] {
    word-wrap: break-word;
    word-break: normal;
  }

  thead > tr > :not(:first-child):before {
    content: '';
    position: absolute;
    left: 0;
    bottom: 25%;
    height: 50%;
    border-left: 1px solid #eaeded;
    box-sizing: border-box;
  }

  tbody > tr {
    &:last-child {
      & > * {
        border-bottom: 0px;
      }
    }

    & > * {
      text-align: left;
      padding: 0px 1rem;
      border-bottom: 1px solid #ececf9;
      box-sizing: border-box;
      min-height: 4rem;
      background: #fff;
      word-break: keep-all;
    }
  }
}

.widget--table_header {
  padding: 1rem;
  border: 1px solid transparent;
  display: block;
  position: relative;
  color: #545b64;
  font-size: 13px;
  font-weight: 700;
  cursor: pointer;
  padding-right: 2.5rem;
  text-decoration: none;
  box-sizing: border-box;
  width: 100%;
  background: none;
  text-align: left;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  text-transform: uppercase;
}

.widget--table_td--content {
  display: flex;
  flex-direction: column;
  padding: 1rem;

  h3 {
    font-weight: 600;
    font-size: 14px;
    line-height: 18.22px;

    &.primary-blue {
      color: #408dfb;
    }

    &.status {
      font-weight: 600;
      font-size: 12px;
      line-height: 15px;
      background: #e4f7f1;
      border: 1px solid #ffffff;
      border-radius: 2px;
      width: 80px;
      height: 20px;
      padding: 2px 0px;
      text-align: center;

      &.success {
        color: #22b378;
        width: 80px;
      }

      &.paid {
        background: #22b378;
        color: #fff;
        width: 80px;
      }

      &.draft {
        background: #f1effb;
        width: 57px;
      }

      &.pending {
        background: #f27914;
        color: #fff;
        width: 135px;
      }
    }
  }

  p {
    font-weight: 500;
    font-size: 13px;
    line-height: 15.22px;
    color: #696d9a;
  }
}

.claims-quick__notices {
  display: flex;
  flex-direction: column;

  .claims-quick__notice {
    display: flex;
    align-items: center;
    margin-bottom: 8px;

    &.success {
      color: #22b378;
    }

    &.draft {
      color: #555558;
    }

    &.pending {
      color: #f27914;
    }

    &.awaiting {
      color: #0073bb;
    }
  }

  .claims-quick__notice-icon {
    margin-right: 8px;

    span {
      position: relative;
      vertical-align: top;
      width: 24px !important;
      display: flex !important;
      align-items: center;
      height: 22px;
      padding: calc((22px - 16px) / 2) 0;
      justify-content: center;
    }
  }
}

.claims-quick__notice-text {
  span {
    font-size: 13px;
    line-height: 20px;
    letter-spacing: normal;
    font-weight: 500;
  }
}

.claims-quick__notice-count {
  margin-left: auto;

  span {
    font-size: 28px;
    line-height: 36px;
    letter-spacing: normal;

    & > svg {
      width: 24px !important;
      height: 24px !important;

      &:not(#\ ) {
        pointer-events: none;
        fill: none;
        vertical-align: top;
        stroke-width: 2px;
      }

      *:not(#\ ) {
        stroke-width: 2px;
      }
    }
  }
}

.widget--hed_action {
  margin-left: auto;
}

.expenses-table_wrapper {
  flex: 1;
  overflow: hidden;

  & > .widget--table {
    & > thead > tr > * {
      background: #fff;
    }
  }
}

.widget--footer__pagination {
  display: flex;
  align-items: center;

  & > .chakra-select__wrapper {
    margin-right: 20px;
  }
}

.widget--footer__total {
  span {
    font-weight: 500;
    font-size: 13px;
    line-height: 16px;
    color: #696d9a;
  }
}
