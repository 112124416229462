.expense_details {
  padding: 20px 0px;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  background: #fff;
}

.expense_total,
.expense_reimbursable {
  text-align: right;
}

.expense_name,
.expense_total,
.expense_reimbursable {
  .heading {
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    color: #2E3151;
  }

  .sub-text {
    color: #696D9A;
    font-size: 12px;
  }
}

.expenses_dash-head {
  margin-left: 12px;
  margin-right: 20px;
  flex-shrink: 0;
  border-bottom: 1px solid;
  border-bottom-color: rgba(234, 234, 234, 1);
}

.expenses_dash-heading {
  font-size: 18px;
  font-weight: 600;
  align-items: center;
  margin: 0 12px 0 8px;
  height: 38px;
}

.expenses_dash-content {
  outline: 0;
  flex-grow: 1;
  overflow-y: auto;
  position: relative;
}

.expenses_dash-content_area {
  width: calc(100% - 20px);
  position: absolute;
  left: 0;
  top: 0;
  padding-left: 20px;
}

.expenses_dash-content_table-area {
  position: relative;
  background: rgba(255, 255, 255, 1);
  color: initial;
  background-color: inherit;
  cursor: default;
  font-size: 14px;
  color: rgba(0, 0, 0, 1);

  thead {
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    z-index: 1;

    tr {
      background: rgba(255, 255, 255, 1);
      vertical-align: top;
      height: 32px;
      border-bottom: 1px solid rgba(200, 200, 200, 1) !important;
      font-size: initial;
      line-height: initial;
      border-color: rgba(234, 234, 234, 1) !important;
      min-height: 34px;

      th {
        vertical-align: top;
        white-space: nowrap;
        text-overflow: ellipsis;
        font-size: 12px;
        font-weight: 400;
        color: rgba(0, 0, 0, 1);
        border-bottom: 1px solid rgba(200, 200, 200, 1) !important;
        border-color: rgba(234, 234, 234, 1) !important;
        padding: 8px;

        &:active,
        &:hover {
          background: rgba(234, 234, 234, 1);
        }
      }
    }


  }

  tbody {
    tr {
      background-color: inherit;
      margin: 0;
      border: none !important;
      min-height: inherit;
      min-height: 38px;
      word-break: break-word;
      background: rgba(255, 255, 255, 1);
      white-space: nowrap;
      border: 0 !important;


      &.expense_row-selected {
        background-color: rgba(222, 236, 249, 1);
      }

      &:hover {
        background-color: rgba(0, 0, 0, .02) !important;

        &.expense_row-selected {
          background-color: rgba(222, 236, 249, 1) !important;
        }


      }

      td {
        min-height: 36px;
        padding: 8px !important;
        border: none !important;
        border: 0 !important;
        // border-bottom: 1px solid rgba(200, 200, 200, 1) !important;
        // border-color: rgba(234, 234, 234, 1) !important;

        .identity {
          align-items: center;
          max-width: 100%;
          width: 100%;
          overflow: hidden;
          text-overflow: ellipsis;
        }

        .avatar {
          width: 20px;
          height: 20px;
          margin-right: 4px;
          border-radius: 50%;
          flex-shrink: 0;

          img {
            border-radius: 50%;
            width: 100%;
            height: 100%;
            display: flex;
            justify-content: center;
            color: white;
            user-select: -webkit-user-select;
            user-select: none;
            object-fit: cover;
            background-color: transparent;
          }
        }

        .name {
          flex: 1;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
          word-wrap: normal;
        }
      }
    }
  }
}

.expenses_dash-head_sep {
  flex: 0 1 auto;
  align-self: center;
  width: 1px;
  min-width: 1px;
  height: 24px;
  background-color: rgba(200, 200, 200, 1);
  margin: 0 8px 0 8px;
}

.expenses_dash-head_filters {
  font-size: 14px;
  font-weight: 400;
  color: rgba(51, 51, 51, 1);
  position: relative;
  background-color: rgba(244, 244, 244, 1);
  height: 40px;
  white-space: nowrap;
  user-select: none;
  height: 48px;
  padding-top: 8px;
  padding-bottom: 8px;
  // overflow: auto;
  flex: 1 1 auto;
  background-color: transparent;
  width: 100px;
}

.expenses_dash-head_filters-menubar {
  // overflow: hidden;
  flex: 1 1 auto;
  vertical-align: top;
  line-height: 40px;
  max-width: 100%;
  margin: 0 20px;
  margin-left: 0;
}

.expenses_dash-head_filters-side {
  flex: 0 0 auto;
  padding-right: 0;
  align-items: center;


  .expenses_dash-head_filters-side-btn {
    border-radius: 4px;

    &[disabled],
    &:disabled {
      background: #fff !important;
      cursor: not-allowed;
      pointer-events: all !important;
    }

    &:hover {
      background-color: rgba(234, 234, 234, 1);
      color: rgba(51, 51, 51, 1);
    }
  }
}

.expenses_dash-head_filters-side-btn {
  font-size: 14px;
  font-weight: 400;
  color: rgba(51, 51, 51, 1);
  position: relative;
  background: none;
  background-color: transparent;
  border: none;
  line-height: 40px;
  min-width: 20px;
  text-align: center;
  padding: 0 4px;
  display: block;
  height: 100%;
  cursor: pointer;
  outline: transparent;
  position: relative;
  padding: 0 8px;
  line-height: 32px;
  margin: auto;
  height: 32px;
  color: rgba(0, 120, 212, 1);
}

.expenses_dash-head_filters-side-select {
  font-size: 14px;
  font-weight: 400;
  position: relative;
  border: none;
  text-align: center;
  padding: 0 4px;
  display: block;
  height: 100%;
  cursor: pointer;
  outline: transparent;
  position: relative;
  line-height: 32px;
  margin: auto !important;
  height: 32px !important;
  border-color: rgba(234, 234, 234, 1) !important;
  border-radius: 4px !important;

  &:focus-visible,
  &[data-focus-visible] {
    border-color: rgba(234, 234, 234, 1) !important;
    box-shadow: none !important;
  }

  &:hover,
  &:active,
  &:focus {
    color: rgba(51, 51, 51, 1) !important;
    box-shadow: none !important;
  }
}

.pill {
  border-radius: 120px;
  border: 1px solid transparent;
  white-space: nowrap;
  display: flex;
  flex-direction: row;
  align-items: center;
  flex-shrink: 0;
  flex-shrink: 0;
  padding: 1px 8px;
  width: max-content;
  text-transform: lowercase;
  font-size: 11px;


  &.default,
  &.draft {
    background-color: #F3F2FB;
    color: rgba(0, 0, 0, .9);
  }

  &.success,
  &.paid {
    background: #22c35e;
    color: #fff;
  }

  &.warning,
  &.pending {
    background: rgb(245, 173, 40);
    color: #fff;
  }

  &.error,
  &.rejected {
    background: brown;
    color: #fff;
  }

  &.info,
  &.awaiting {
    background: rgb(0, 103, 181);
    color: #fff;
  }
}

.bg {

  &.default,
  &.draft {
    background-color: #F3F2FB;
    color: rgba(0, 0, 0, .9);
  }

  &.success,
  &.paid {
    background: #22c35e;
    color: #fff;
  }

  &.warning,
  &.pending {
    background: rgb(245, 173, 40);
    color: #fff;
  }

  &.error,
  &.rejected {
    background: brown;
    color: #fff;
  }

  &.info,
  &.awaiting {
    background: rgb(0, 103, 181);
    color: #fff;
  }
}

.color {
  &.default {
    color: inherit !important;
  }

  &.success {
    color: rgba(127, 184, 0, 1) !important;

  }

  &.warning {
    color: rgba(177, 133, 37, 1) !important;
  }

  &.error {
    color: rgba(232, 17, 35, 1) !important;
  }

  &.info {
    color: rgba(0, 91, 161, 1) !important;
  }
}

.table_checker {
  height: 16px;
  width: 16px;
  border-radius: 4px;
  border: 1.5px solid #9596AD;
  color: rgba(0, 91, 161, 1) !important;
}


.debounced--input {
  width: 100%;
  height: 32px;
  display: block;
  border-radius: 4px;
  font-size: 13px;
  border: 1px solid;
  border-color: rgb(234, 234, 234) !important;
  border-radius: 4px !important;
  padding: 0 8px;
  font-weight: 500;
  outline: none;

  &:focus-visible,
  &:active,
  &:visited,
  &:hover {
    border: 1px solid;
    border-color: rgb(0, 103, 181) !important;
    outline: none;
  }
}

.no-expense-claim {

  .no-expense-claim--inner {
    margin: 0 auto;
    max-width: 960px;
    padding: 52px;
    text-align: center;
    border-radius: 8px;
    align-items: center;
    flex-direction: column;
  }

  .no-expense-claim--icon {
    margin: 0;
    padding: 0;
    border: 0;
    cursor: pointer;
    font-size: 100%;
    vertical-align: baseline;
    margin-bottom: 10px;
    width: 60px;
  }

  .no-expense-claim--text {
    font-size: 14px;
    font-weight: 700;
    line-height: 1.4;
    margin-bottom: 8px;
    color: #0b1b34;
  }

  .no-expense-claim--btn {
    transition: none;
    border-width: 1px;
    font-size: 12px;
    line-height: 22px;
    font-weight: 700;
    text-align: center;
    vertical-align: middle;
    touch-action: manipulation;
    border: 1px solid transparent;
    white-space: nowrap;
    padding: 4px 10px;
    border-radius: 8px;

    &.add {
      background: #0185ff;
      color: #fff;

      &:active,
      &:hover {
        background: #0680f1;
        border-color: transparent;
        color: #fff;
        text-decoration: none;
      }

      &:focus {
        background: #06509a;
        border-color: transparent;
        box-shadow: 0 0 0 2px rgb(7 77 146 / 50%);
        color: #fff;
      }
    }
  }
}

#expense--action {
  .expense-action--btn {
    transition: none;
    border-width: 1px;
    font-size: 12px;
    line-height: 22px;
    background: #0185ff;
    color: #fff;
    font-weight: 700;
    text-align: center;
    vertical-align: middle;
    touch-action: manipulation;
    border: 1px solid transparent;
    white-space: nowrap;
    padding: 4px 10px;
    border-radius: 8px;



    &:active,
    &:hover {
      background: #0680f1;
      border-color: transparent;
      color: #fff;
      text-decoration: none;
    }

    &.is-left {
      margin-right: 5px;
    }

    &.bulk-add {
      background: #dfdfdf;
      color: #0b1b34;

      &:active,
      &:hover {
        background: #cccbcb;
        border-color: transparent;
      }

      &:focus {
        box-shadow: 0 0 0 2px rgb(185 182 182 / 50%);
      }
    }


  }
}

.expense-list__action_count {
  color: #408DFB;
  margin-bottom: 0;
  font-weight: 700;
  text-align: center;
  vertical-align: middle;
  cursor: pointer;
  background-image: none;
  border: 1px solid transparent;
  white-space: nowrap;
  padding: 0px 6px;
  font-size: 14px;
  line-height: 1.428571429;
  border-radius: 8px;
  line-height: 22px;
  background-color: #dfdfdf;
  margin-left: 5px;
}


#expense--action__container {
  .expense--action__btn {
    margin-bottom: 0;
    font-weight: 700;
    text-align: center;
    vertical-align: middle;
    cursor: pointer;
    background-image: none;
    border: 1px solid transparent;
    white-space: nowrap;
    padding: 4px 10px;
    font-size: 14px;
    line-height: 1.428571429;
    border-radius: 8px;
    line-height: 22px;

    &.view {
      background: #dfdfdf;
      color: #0b1b34;

      &:active,
      &:hover {
        background: #cccbcb;
        border-color: transparent;
      }

      &:focus {
        box-shadow: 0 0 0 2px rgb(185 182 182 / 50%);
      }
    }

    &.delete {
      background: #fc3826;
      color: #fff;

      &:active,
      &:hover {
        background: #ec1703;
      }

      &:focus {
        box-shadow: 0 0 0 2px rgb(252 56 38 / 50%);
      }
    }

    &.is-left {
      margin-right: 5px;
    }

    &.edit {
      background: #0185ff;
      color: #fff;

      &:active,
      &:hover {
        background: #0680f1;
        border-color: transparent;
        color: #fff;
        text-decoration: none;
      }

      &:focus {
        background: #0680f1;
        border-color: transparent;
        box-shadow: 0 0 0 2px rgb(4 122 231/ 50%);
        color: #fff;
      }
    }
  }
}

.hub-modal--header {
  border-radius: 8px 8px 0 0 !important;
  border: none !important;
  // min-height: 29px !important;
  padding: 10px 20px !important;
  border-bottom: 1px solid #ececec !important;
  box-shadow: 0 1px 2px 0 rgba(60, 64, 67, 0.302), 0 1px 3px 1px rgba(60, 64, 67, 0.149);
  line-height: 28px !important;
  top: 0 !important;

  h3 {
    font-size: 17px;
    font-weight: 700;
    color: #0b1b34;
  }
}

.hub-modal--body {
  padding-inline-start: 0 !important;
  padding-inline-end: 0 !important;
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}

.hub-modal--btn {
  font-weight: 400 !important;
  // height: 50px !important;
  height: 27px !important;
  margin-top: 0 !important;
  padding: 6px 14px !important;

  // margin-top: 0 !important;
  // padding: 10px 20px !important;
  right: 0 !important;
  color: #fc3826 !important;
  font-size: 14px !important;
  line-height: 1;
  // top: 0 !important;
  top: 9px !important;
  margin-right: 10px;

  &:active,
  &:hover,
  &:focus-within {
    text-decoration: none !important;
    background: #fc3826 !important;
    color: #fff !important;
    box-shadow: 0 0 0 2px rgba(252, 56, 38, 0.5);
  }
}

.hub-modal--footer {
  border-radius: 0 0 8px 8px !important;
  border: none !important;
  // min-height: 29px !important;
  padding: 10px 20px !important;
  border-bottom: 1px solid #ececec !important;
  line-height: 28px !important;
  box-shadow: 0 1px 2px 0 rgba(60, 64, 67, 0.302), 0 1px 3px 1px rgba(60, 64, 67, 0.149);

  .hub-modal--footer_btn {
    margin-bottom: 0;
    font-weight: 700;
    text-align: center;
    vertical-align: middle;
    cursor: pointer;
    background-image: none;
    border: 1px solid transparent;
    white-space: nowrap;
    // padding: 4px 10px;
    padding: 10px 16px;
    font-size: 14px;
    line-height: 1.428571429;
    border-radius: 8px;
    line-height: 22px;
    border-color: transparent;

    &.is-left {
      margin-right: 5px;
    }

    &.cancel {
      background: #fc3826;
      color: #fff;

      &:active,
      &:hover {
        background: #ec1703;
        border-color: transparent;
        color: #fff;
      }

      &:focus {
        background: #ec1703;
        border-color: transparent;
        box-shadow: 0 0 0 2px rgb(252 56 38 / 50%);
        color: #fff;
      }
    }

    &.delete {
      background: #fc3826;
      color: #fff;

      &:active,
      &:hover {
        background: #ec1703;
        border-color: transparent;
        color: #fff;
      }

      &:focus {
        background: #ec1703;
        border-color: transparent;
        box-shadow: 0 0 0 2px rgb(252 56 38 / 50%);
        color: #fff;
      }
    }

    &.ok {
      background: #16ec93;
      color: #fff;
      text-decoration: none;

      &:active,
      &:hover {
        background: #03d47c;
        text-decoration: none;
      }

      &:focus {
        background: #03d47c;
        border-color: transparent;
        box-shadow: 0 0 0 2px rgb(4 190 113 /50%);
        color: #fff;
      }
    }
  }
}

.expense-menu {
  .expense-menu--button {
    margin-bottom: 0;
    font-weight: 700;
    text-align: center;
    vertical-align: middle;
    cursor: pointer;
    background-image: none;
    border: 1px solid transparent;
    white-space: nowrap;
    // padding: 4px 10px;
    padding: 10px 16px;
    font-size: 14px;
    line-height: 1.428571429;
    border-radius: 8px;
    line-height: 22px;
    border-color: transparent;
    margin-right: 5px;

    &:last-child {
      margin-right: 0px;
    }

    &.re-submit,
    &.approve {
      background: #16ec93;
      color: #fff;
      text-decoration: none;

      &:active,
      &:hover {
        background: #03d47c;
        text-decoration: none;
      }

      &:focus {
        background: #03d47c;
        border-color: transparent;
        box-shadow: 0 0 0 2px rgb(4 190 113 /50%);
        color: #fff;
      }
    }

    &.delete,
    &.reject {
      background: #fc3826;
      color: #fff;

      &:active,
      &:hover {
        background: #ec1703;
        border-color: transparent;
        color: #fff;
      }

      &:focus {
        background: #ec1703;
        border-color: transparent;
        box-shadow: 0 0 0 2px rgb(252 56 38 / 50%);
        color: #fff;
      }
    }

    &.edit {
      background: #0185ff;
      color: #fff;

      &:active,
      &:hover {
        background: #0680f1;
        border-color: transparent;
        color: #fff;
        text-decoration: none;
      }

      &:focus {
        background: #0680f1;
        border-color: transparent;
        box-shadow: 0 0 0 2px rgb(4 122 231/ 50%);
        color: #fff;
      }
    }


    &.revert-to-draft {}

  }
}


#expense_action {
  .expense_action--btn {
    margin-bottom: 0;
    font-weight: 700;
    text-align: center;
    vertical-align: middle;
    cursor: pointer;
    background-image: none;
    border: 1px solid transparent;
    white-space: nowrap;
    padding: 4px 10px;
    font-size: 14px;
    line-height: 1.428571429;
    border-radius: 8px;
    line-height: 22px;
    border-color: transparent;
    margin-right: 5px;

    &:last-child {
      margin-right: 0px;
    }

    &.delete {
      background: #fc3826;
      color: #fff;

      &:active,
      &:hover {
        background: #ec1703;
        border-color: transparent;
        color: #fff;
      }

      &:focus {
        background: #ec1703;
        border-color: transparent;
        box-shadow: 0 0 0 2px rgb(252 56 38 / 50%);
        color: #fff;
      }
    }

    &.view {
      background: #0185ff;
      color: #fff;

      &:active,
      &:hover {
        background: #0680f1;
        border-color: transparent;
        color: #fff;
        text-decoration: none;
      }

      &:focus {
        background: #0680f1;
        border-color: transparent;
        box-shadow: 0 0 0 2px rgb(4 122 231/ 50%);
        color: #fff;
      }
    }

  }
}