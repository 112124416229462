.navigation-container {
  width: 100%;
  border-bottom: 1px solid rgba(0, 0, 0, .1);
  transition: height 1ms cubic-bezier(.4, 0, .2, 1);
  z-index: 10;

  &.sidebar-open {
    height: 100%
  }

  &.sidebar-closed {
    .navigation-section {
      position: absolute;
      left: -100%;

      @media (min-width: 900px) {
        position: absolute;
        width: 260px;
        left: -260px;
      }
    }
  }

  @media (min-width: 900px) {
    width: auto;
    height: 100%;
    left: 0;
  }

  .navigation-region {
    width: 100%;
    position: relative;
    transition: all 1ms cubic-bezier(.4, 0, .2, 1);
    flex-direction: column;

    &.sidebar-area {
      background: #F3F2FB;
      border-color: rgba(234, 234, 234, 1);
      border-right: 1px solid rgba(0, 0, 0, .1);
      border-right: 1px solid var(--palette-black-alpha-10, rgba(0, 0, 0, .1));
      color: #0b1b34;
      white-space: nowrap;

      @media (min-width: 900px) {
        &.collapsed {
          .sidebar-collapse_section {
            flex-direction: column;
          }

          .sidebar-collapse_action {
            align-items: center;
            justify-content: center;
            padding: 5px;

            .sidebar-close_btn {
              margin-right: 0;
            }
          }

          .navigation-section {
            width: auto;
            position: relative;
          }

          .sidebar-collapse_section {
            width: auto;
          }

          .expand-only {
            display: none;
          }
        }
      }


    }

    .sidebar-collapse_section {
      width: 100%;
      position: -webkit-sticky;
      position: sticky;
      border-bottom: 1px solid rgba(0, 0, 0, .1);
      padding: 0 15px;

      &+.separator {
        display: none;
      }

      @media (min-width: 900px) {
        width: 260px;
        left: 0;
        position: static;
        border-bottom: 0px;
        padding: 0;
      }

      .sidebar-collapse_hamburger {
        &.left {
          margin-right: 10px;
          align-items: center;
          justify-content: center;

          @media (min-width: 900px) {
            display: none;
          }
        }

        &.right {
          display: none;

          @media (min-width: 900px) {
            display: block;
          }
        }
      }

      .sidebar-collapse_close,
      .sidebar-collapse_hamburger {

        &:hover,
        &:focus {
          color: rgba(0, 90, 158, 1);
          text-shadow: 0 0 4px rgb(0 120 212 / 50%);
        }
      }
    }

    .navigation-section {
      border-bottom: 1px solid rgba(0, 0, 0, .1);
      overflow: hidden;
      overflow-y: auto;
      flex-direction: column;
      flex-grow: 1;
      margin: 4px 0;
      width: 100%;

      @media (min-width: 900px) {
        width: 260px;
        left: 0;
      }

      &+.separator {
        display: none;
      }
    }
  }

}

.sidebar-close_btn {
  padding: 8px 10px;
  border-radius: 8px;
  line-height: 22px;
  margin-right: 5px;
  font-size: 13px;
  align-self: center;
  display: inline-flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
  color: #0b1b34;

  &:hover,
  &:focus-visible,
  &:focus {
    color: rgba(0, 90, 158, 1);
    text-shadow: 0 0 4px rgb(0 120 212 / 50%);
    background: #cccbcb;
    border-color: transparent;
  }

  &:focus-visible,
  &:focus {
    box-shadow: 0 0 0 2px rgb(185 182 182 / 50%);
  }
}


// .navigation-region {
//   position: relative;
//   width: 48px;
//   transition: width 1ms cubic-bezier(.4, 0, .2, 1);

//   &.expanded {
//     transition-duration: 200ms;
//     width: 260px;

//     .sidebar-collapse_section {
//       flex-direction: row;
//     }
//   }

//   &.collapsed {
//     .sidebar-collapse_section {
//       flex-direction: column;
//     }

//     .expand-only {
//       display: none;
//     }
//   }

//   .separator {
//     background-color: rgba(0, 0, 0, .1);
//     height: 1px;
//     margin: 0 10px;
//   }
// }

.text-ellipsis {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.sidebar-btn {
  padding-bottom: 12px;
  padding-top: 12px;
  width: 47px;
}

// .sidebar-btn_cont {
//   img {
//     width: 100%;
//     height: 100%;
//     display: flex;
//     justify-content: center;
//     color: white;
//     background-color: rgba(166, 166, 166, 1);
//     user-select: none;
//     border-radius: 4px;
//     object-fit: cover;
//     background-color: transparent;
//   }
// }

// .navigation-section {
// overflow: hidden;
// overflow-y: auto;
// flex-direction: column;
// flex-grow: 1;
// margin: 4px 0;
// }

.accordion-nav-item,
.chakra-accordion {
  border-bottom-width: 0px !important;

  &:last-of-type {
    border-bottom-width: 0px !important;
  }
}

.accordion-nav--button {
  flex-direction: row;
  overflow: hidden;
  position: relative;
  align-items: center;
  flex-grow: 1;
  border-radius: 2px;
  cursor: pointer;
  outline: transparent;
  text-decoration: none;
  transition: color 80ms cubic-bezier(.165, .84, .44, 1), background 80ms linear;
  color: rgba(0, 0, 0, .9);
  padding: 0px !important;
  padding-right: 4px !important;
  background: rgb(244, 244, 244) !important;

  &:focus,
  &:hover {
    background: rgba(0, 0, 0, .08) !important;
  }

  &[aria-expanded=true] {
    // background-color: rgb(231, 245, 255) !important;
    background: rgba(65, 141, 251, 0.2) !important;
    // color: rgb(34, 139, 230);
    color: rgba(0, 120, 212, 1);

    &:before {
      background: rgba(0, 120, 212, 1);
      bottom: 0;
      content: "";
      left: 0;
      position: absolute;
      top: 0;
      width: 3px;
    }

    &+.chakra-collapse {
      color: inherit;

      .accordion-nav--link {
        text-decoration: none;

        &:focus,
        &:hover,
        &.active {
          background: rgba(0, 0, 0, .08);
          text-decoration: none;
        }

        &.active {
          background: rgba(0, 120, 212, 1);
          color: #fff;
          text-decoration: none;

          &:before {
            background: #fff;
            bottom: 0;
            content: "";
            left: 0;
            position: absolute;
            top: 0;
            width: 3px;
          }
        }
      }
    }
  }
}

.accordion-nav--button-icon {
  padding-bottom: 12px;
  padding-top: 12px;
  width: 47px;
}

.accordion-nav--link {
  flex-direction: row;
  overflow: hidden;
  position: relative;
  align-items: center;
  flex-grow: 1;
  border-radius: 2px;
  cursor: pointer;
  outline: transparent;
  text-decoration: none;
  transition: color 80ms cubic-bezier(.165, .84, .44, 1), background 80ms linear;
  color: rgba(0, 0, 0, .9);
  padding: 0px !important;
  padding-right: 4px !important;
  display: flex;
  text-decoration: none;

  &:focus,
  &:hover {
    background: rgba(0, 0, 0, .08);
    text-decoration: none;
  }
}